const randomIdGenerator = () => {
	let s4 = () => {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	};

	return (
		s4() +
		s4() +
		'-' +
		s4() +
		'-' +
		s4() +
		'-' +
		s4() +
		'-' +
		s4() +
		s4() +
		s4()
	);
};

export const tools = [
	{
		id: randomIdGenerator(),
		title: 'Phonesites',
		subTitle: 'Create your own sales funnel, without tech or design skills',
		affiliateLink: 'https://a.phonesites.com/hm0qx2q42d58',
		cardImageLink:
			'https://res.cloudinary.com/fullstackcaveman/image/upload/v1651685015/Phonesites%20Assets/IG_Banner_puekvf.png',
		description1:
			'Phonesites has everything you need to grow your business: customizable sales funnels, landing pages, and lead capture forms. Try one of our pre-made designs or create your own from scratch.',
		description2:
			'Recieve a free upgrade to a PollMagic Basic Plan and a pre-built poll template when subscribing to a paid Phonesites plan using the link above!',
		tags: ['Landing Pages', 'Sales Funnels', 'Lead Capture'],
	},
	{
		id: randomIdGenerator(),
		title: 'ActiveCampaign',
		subTitle: 'Drive growth with customer experience automation',
		affiliateLink: 'https://www.activecampaign.com/?_r=WMC2HAYB',
		cardImageLink:
			'https://res.cloudinary.com/fullstackcaveman/image/upload/v1651724949/ActiveCampaign/activecampaign-affiliate_hrensn.png',
		description1:
			'ActiveCampaign gives you the email marketing, marketing automation, and CRM tools you need to create incredible customer experiences.',
		description2:
			'ActiveCampaign plays well with your favorite software. With 870+ integrations in our app marketplace like Shopify, WordPress, and Zapier available, you’ll have all the marketing tools you need to succeed.',
		tags: ['Email Marketing', 'Marketing Automation', 'CRM'],
	},
	{
		id: randomIdGenerator(),
		title: 'Leadpages',
		subTitle: 'Easily Build Landing Pages and Websites',
		affiliateLink: 'https://try.leadpages.com/49qh6b78r0z7',
		cardImageLink:
			'https://res.cloudinary.com/fullstackcaveman/image/upload/v1651695449/Leadpages/01-Leadpages-brand-level_nxq5mw.gif',
		description1:
			'Use our no-code Drag & Drop Builder to start designing high-converting landing pages and websites within minutes. Choose from over 200+ beautiful modern landing page templates.',
		description2: 'Your only risk? Is that you don’t start now.',
		tags: ['Landing Pages'],
	},
	{
		id: randomIdGenerator(),
		title: 'Texta',
		subTitle: "You're Only 1 Click Away From Your Perfect Blog Post",
		affiliateLink: 'https://www.texta.ai/?via=fullstackcaveman',
		cardImageLink: '',
		youTubeEmbed: (
			<iframe
				src='https://www.youtube.com/embed/udd5_o7D8tM'
				title='YouTube video player'
				frameborder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowfullscreen
			></iframe>
		),
		description1:
			'Create automated content marketing workflows that take care of repetitive tasks in the background, so you can focus on what matters most! No technical knowledge required',
		description2:
			"7-Day Free Trial. No Credit Card Required. You're Only 1 Click Away From Your Perfect Blog Post!",
		tags: ['Blog', 'Content Writer'],
	},
	{
		id: randomIdGenerator(),
		title: 'Pabbly',
		subTitle: 'Easily connect multiple applications to automate your tasks',
		affiliateLink:
			'https://payments.pabbly.com/api/affurl/RVYZ07kQyUZ0Z1HUKZ1m/XSK0kTyCJWDV4mKo?target=nMfdbf0I90K3UdJn',
		cardImageLink:
			'https://res.cloudinary.com/fullstackcaveman/image/upload/v1659210707/PollMagic/pabbly_connect_xj0zku.png',
		description1:
			'Pabbly Connect is an integration application that connects multiple applications together, so that you can send data from one of your applications to another and sync your data across multiple applications.',
		description2:
			'Connect your favorite applications within minutes and say goodbye to manual work',
		tags: ['Zapier', 'Workflow Automator'],
	},
];
