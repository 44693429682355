import { Link, useNavigate } from 'react-router-dom';
import { browserName } from 'react-device-detect';
import { logout } from '../../actions/userActions';
import { Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Logo from '../../images/logo.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import StarIcon from '@mui/icons-material/Star';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = useState(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

	const isMenuOpen = !!anchorEl;
	const isMobileMenuOpen = !!mobileMoreAnchorEl;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const handleProfileMenuOpen = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleOptionSelect = (input) => {
		handleMenuClose();
		if (input === 'Logout') {
			dispatch(logout());
		} else if (input === 'Profile') {
			navigate('/profile');
		} else if (input === 'Manage Plan') {
			navigate('/my-account');
		} else if (input === 'Admin') {
			navigate('/admin');
		}
	};

	useEffect(() => {
		const navbar = document.getElementById('navbar');
		const dashboardLink = document.getElementById('dashboard-link');
		const toolsLink = document.getElementById('tools-link');

		if (browserName === 'Firefox') {
			window.onscroll = function (e) {
				const scrollPosition = window.scrollY;

				if (scrollPosition >= 50) {
					navbar.classList.add('scrolled');
				} else {
					navbar.classList.remove('scrolled');
				}
			};
		} else {
			document.addEventListener('scroll', (e) => {
				const scrollPosition = e.path[1].scrollY;

				if (scrollPosition >= 50) {
					navbar.classList.add('scrolled');
				} else {
					navbar.classList.remove('scrolled');
				}
			});
		}

		if (window.location.pathname === '/dashboard') {
			dashboardLink.classList.add('active');
			toolsLink.classList.remove('active');
		} else if (window.location.pathname === '/tools') {
			dashboardLink.classList.remove('active');
			toolsLink.classList.add('active');
		} else if (window.location.pathname === '/reset-password') {
			navbar.classList.add('hidden');
		} else {
			dashboardLink.classList.remove('active');
			toolsLink.classList.remove('active');
		}
	}, []);

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			className=''
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
				Profile
			</MenuItem>
			{userInfo && userInfo.isAdmin ? (
				<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
					Admin
				</MenuItem>
			) : null}
			<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
				Manage Plan
			</MenuItem>
			<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
				Logout
			</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}
		>
			<MenuItem onClick={handleProfileMenuOpen}>Profile</MenuItem>
			{userInfo && userInfo.isAdmin ? (
				<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
					Admin
				</MenuItem>
			) : null}
			<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
				Manage Plan
			</MenuItem>
			<MenuItem onClick={(e) => handleOptionSelect(e.target.innerText)}>
				Logout
			</MenuItem>
		</Menu>
	);

	return (
		<div id='navbar' className='navbar'>
			<AppBar className='appbar' position='static' elevation={0}>
				<Toolbar className='toolbar'>
					<div className='nav-left'>
						<div className='logo-container'>
							<Link to='/' className='home-link'>
								<img src={Logo} alt='Poll Magic Logo' />
							</Link>
						</div>
					</div>

					<div className='links'>
						<Link to='/dashboard'>
							<Typography id='dashboard-link'>DASHBOARD</Typography>
						</Link>
						<Link to='/tools'>
							<Typography id='tools-link'>TOOLS</Typography>
						</Link>
					</div>

					{userInfo ? (
						<div className='nav-right'>
							{userInfo.subscription === 'Admin' ? (
								<Typography variant='h5'>{userInfo.subscription}</Typography>
							) : userInfo.subscription === 'Agency' ? null : (
								<Link to='/my-account'>
									<Button
										variant='contained'
										sx={{
											backgroundColor: '#17aa21',
											'&:hover': {
												backgroundColor: '#138b1b',
											},
										}}
									>
										<StarIcon sx={{ marginRight: '5px' }} />
										<Typography sx={{ marginTop: '2px', fontWeight: 'bold' }}>
											UPGRADE
										</Typography>
									</Button>
								</Link>
							)}
							<Tooltip title='Open Settings'>
								<AccountCircle
									color='primary'
									onClick={handleProfileMenuOpen}
									sx={{
										transition: 'all linear 0.2s',
										ml: 3,
										color: '#fff',
										fontSize: '40px',
										'&:hover': {
											background: 'none',
											cursor: 'pointer',
											color: '#1e71c5',
										},
									}}
								/>
							</Tooltip>
						</div>
					) : (
						<>
							<Link to='/register'>
								<Button>Sign Up</Button>
							</Link>
							<Link to='/login'>
								<Button variant='contained'>Login</Button>
							</Link>
						</>
					)}
				</Toolbar>
			</AppBar>
			{renderMobileMenu}
			{renderMenu}
		</div>
	);
};

export default Header;
