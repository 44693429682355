import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
	const user = JSON.parse(localStorage.getItem('userInfo'));

	return user ? <>{children}</> : <Navigate to='/login' />;

	// <Route
	// 	{...rest}
	// 	render={(props) => {
	// 		if (localStorage.getItem('userInfo')) {
	// 			return <Component {...rest} {...props} />;
	// 		} else {
	// 			return <Redirect to='/login' />;
	// 		}
	// 	}}
	// />
};

export default PrivateRoute;
