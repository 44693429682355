import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { axiosWithAuth } from '../../utils/axiosWithAuth';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chart from '../elements/Chart';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Copy from '@mui/icons-material/ContentCopy';
import DeleteButton from '../buttons/DeleteButton';
import Divider from '@mui/material/Divider';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditChartLinkForm from '../elements/EditChartLinkForm';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Link from '@mui/material/Link';
import LoadingPageChart from '../scripts/LoadingPageChart';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import NoBtnEditor from './NoBtnEditor';
import NoButton from '../buttons/NoButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Paper from '@mui/material/Paper';
import PollPageSnippet from '../scripts/PollPageSnippet';
import PollTableEditor from './PollTableEditor';
import Popover from '@mui/material/Popover';
import RedirectSnippet from '../scripts/RedirectSnippet';
import SocialProofSnippet from '../scripts/SocialProofSnippet';
import SocialProofTextEditor from './SocialProofTextEditor';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import YesBtnEditor from './YesBtnEditor';
import YesButton from '../buttons/YesButton';

const Poll = (props) => {
	const pollData = props;
	const {
		id,
		pollName,
		yesVotes,
		noVotes,
		totalVotes,
		createdDate,
		yesBtnText,
		noBtnText,
		protocol,
		pollPageLink,
		pollPagePath,
		errorProtocol,
		errorPageLink,
		errorPagePath,
		autoForwardProtocol,
		autoForwardLink,
		autoForwardPath,
		redirectDelay,
		showChartLink,
		yesBtnProtocol,
		yesBtnLink,
		yesBtnPath,
		noBtnProtocol,
		noBtnLink,
		noBtnPath,
		chartLink,
		chartLinkText,
		setEditSocialProofDialogOpen,
	} = pollData;

	const errorRouting = `${errorProtocol}${errorPageLink}/${errorPagePath}`;
	const noBtnRouting = `${noBtnProtocol}${noBtnLink}/${noBtnPath}`;
	const pollRouting = `${protocol}${pollPageLink}/${pollPagePath}`;
	const redirectRouting = `${autoForwardProtocol}${autoForwardLink}/${autoForwardPath}`;
	const yesBtnRouting = `${yesBtnProtocol}${yesBtnLink}/${yesBtnPath}`;

	const [anchorEl, setAnchorEl] = useState(false);
	const [checked, setChecked] = useState(showChartLink);
	const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
	const [deleteDisabled, setDeleteDisabled] = useState(true);
	const [disableSwitch, setDisableSwitch] = useState(false);
	const [fullModalOpen, setFullModalOpen] = useState(false);
	const [noBtnModalOpen, setNoBtnModalOpen] = useState(false);
	const [noButtonText, setNoButtonText] = useState(noBtnText);
	const [openPoll, setOpenPoll] = useState(false);
	const [popYesNo, setPopYesNo] = useState('');
	const [socialTextModalOpen, setSocialTextModalOpen] = useState(false);
	const [yesBtnModalOpen, setYesBtnModalOpen] = useState(false);
	const [yesButtonText, setYesButtonText] = useState(yesBtnText);
	const [disableWebhookSwitch, setDisableWebhookSwitch] = useState(false);
	const [webhookChecked, setWebhookChecked] = useState(false);

	const [editPollModalOpen, setEditPollModalOpen] = useState(false);

	const [, setChartEditLinkDialogOpen] = useState(false);

	const [webhookSelection, setWebhookSelection] = useState();

	const handleWebhookSelection = (e) => {
		console.log(e.target.value);
		setWebhookSelection(e.target.value);
	};

	const handlePopoverOpen = (e) => {
		setPopYesNo(e.target.id);
		setAnchorEl(e.currentTarget);
	};

	const handleFullModal = () => setFullModalOpen(!fullModalOpen);
	const handleNoBtnHover = () => setNoButtonText('CLICK TO EDIT');
	const handleNoBtnLeave = () => setNoButtonText(noBtnText);
	const handleNoBtnModal = () => setNoBtnModalOpen(!noBtnModalOpen);
	const handlePopoverClose = (e) => setAnchorEl(null);
	const handleYesBtnHover = () => setYesButtonText('CLICK TO EDIT');
	const handleYesBtnLeave = () => setYesButtonText(yesBtnText);
	const handleYesBtnModal = () => setYesBtnModalOpen(!yesBtnModalOpen);
	const openDeletePopover = (e) => setDeleteAnchorEl(e.currentTarget);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		if (userInfo.subscription === 'Beta' || userInfo.subscription === 'Free') {
			setDisableSwitch(true);
		}
	}, [userInfo]);

	const handleChartLinkEditModal = () => {
		if (userInfo.subscription === 'Free') {
			setChartEditLinkDialogOpen(true);
		} else {
			setEditPollModalOpen(true);
		}
	};

	const switchData = {
		...pollData,
		showChartLink: !checked,
	};

	const handleWebhookSwitchChange = () => {
		setWebhookChecked(!webhookChecked);
		//
	};

	const handleCopyWebhook = (e) => {
		console.log(id);
	};

	const handleSwitchChange = () => {
		// Combats removing disabled flag in DOM
		if (userInfo.subscription === 'Free' || userInfo.subscription === 'Beta') {
			alert('Nice try! No cheating allowed!');
			return;
		}
		setChecked(!checked);
		axiosWithAuth()
			.put(`/api/polls/${pollData.id}`, switchData)
			.then(() => {
				// prevent spamming switch
				setDisableSwitch(true);
				setTimeout(() => {
					setDisableSwitch(false);
				}, 10 * 1000);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const handleDelete = (id) => {
		props.handleDeletePoll(id);
		setDeleteAnchorEl(null);
	};

	const open = anchorEl;
	const deleteOpen = deleteAnchorEl;

	const date = new Date(createdDate);

	const btnPopupText = (yesNo) => {
		return `The "${yesNo}" below is a visual representation of the text on	your poll's "${yesNo}". The text must exactly match the "${yesNo}" on your poll page or the script snippets will not work. If the button does not match your poll's "${yesNo}", click the button below to	edit the text.`;
	};

	return (
		<Paper elevation={5} className='poll'>
			<span className='table-drag-indicator'>
				<DragIndicatorIcon />
			</span>
			<TableContainer>
				<Table align='center'>
					<TableHead>
						<TableRow sx={{ backgroundColor: '#228bf3' }}>
							<TableCell align='center' sx={{ color: '#fff' }}>
								<Typography>Poll Name</Typography>
							</TableCell>
							<TableCell align='center' sx={{ color: '#fff' }}>
								<Typography>Yes Votes</Typography>
							</TableCell>
							<TableCell align='center' sx={{ color: '#fff' }}>
								<Typography>No Votes</Typography>
							</TableCell>
							<TableCell align='center' sx={{ color: '#fff' }}>
								<Typography>Total Votes</Typography>
							</TableCell>
							<TableCell align='center'>
								<MoreVertRoundedIcon
									sx={{
										borderRadius: '50%',
										padding: '2px',
										marginTop: '4px',
										color: 'white',
										'&:hover': {
											backgroundColor: '#1976D2',
											boxShadow: 'none',
											cursor: 'pointer',
										},
									}}
									onClick={openDeletePopover}
								/>
								<Popover
									open={deleteOpen}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center',
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
									anchorEl={deleteAnchorEl}
								>
									<Card className='delete-modal'>
										<CardContent className='delete-modal-content'>
											<FormGroup className='delete-modal-form'>
												<Typography variant='h6'>Delete Poll</Typography>
												<Typography>
													Are you sure? Deleting a poll is permanant and cannot
													be undone.
												</Typography>

												<FormControlLabel
													className='checkbox-group'
													control={
														<Checkbox
															size='small'
															onChange={() =>
																setDeleteDisabled(!deleteDisabled)
															}
														/>
													}
													label="I understand that this can't be undone"
												/>
												<div className='delete-poll-button-group'>
													<Button
														variant='outlined'
														onClick={() => setDeleteAnchorEl(null)}
													>
														Cancel
													</Button>

													<DeleteButton
														disabled={deleteDisabled}
														variant='contained'
														onClick={() => handleDelete(id)}
														sx={{ padding: '5px 15px' }}
													>
														DELETE
													</DeleteButton>
												</div>
											</FormGroup>
										</CardContent>
									</Card>
								</Popover>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell align='center' className='poll-name'>
								<Link
									underline='none'
									variant='subtitle1'
									color='black'
									target='_blank'
									rel='noreferrer'
									href={pollRouting}
								>
									<div className='poll-name-text'>
										{pollName}
										<span>
											<OpenInNewIcon fontSize='small' />
										</span>
									</div>
								</Link>
							</TableCell>
							<TableCell align='center'>
								<Typography>{parseInt(yesVotes).toLocaleString()}</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography>{parseInt(noVotes).toLocaleString()}</Typography>
							</TableCell>
							<TableCell align='center'>
								<Typography>{totalVotes.toLocaleString()}</Typography>
							</TableCell>
							<TableCell align='center'>
								{openPoll ? (
									<KeyboardArrowUpIcon
										className='tableChevron'
										name='chevron up'
										onClick={() => setOpenPoll(!openPoll)}
									/>
								) : (
									<KeyboardArrowDownIcon
										className='tableChevron'
										name='chevron down'
										onClick={() => setOpenPoll(!openPoll)}
									/>
								)}
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell
								align='center'
								className='inner-wrapper'
								style={{ paddingBottom: 0, paddingTop: 0 }}
								colSpan={6}
							>
								<Collapse in={openPoll} timeout='auto' unmountOnExit>
									<Typography variant='h5' className='management-header'>
										Poll Management
									</Typography>
									<Table className='inner-table'>
										<TableHead>
											<TableRow>
												<TableCell align='center'>
													<Typography>Created Date</Typography>
												</TableCell>
												<TableCell align='center'>
													<Grid
														sx={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<Typography>"Yes" Button Text</Typography>
														<span
															aria-owns={
																anchorEl ? 'mouse-over-popover' : undefined
															}
															aria-haspopup='true'
															onMouseEnter={handlePopoverOpen}
															onMouseLeave={handlePopoverClose}
															style={{ height: '20px' }}
														>
															<HelpOutlineIcon
																id='Yes Button'
																sx={{ height: '18px', color: 'lightgrey' }}
															/>
														</span>
													</Grid>
												</TableCell>
												<TableCell align='center'>
													<Grid
														sx={{
															display: 'flex',
															justifyContent: 'center',
															alignItems: 'center',
														}}
													>
														<Typography>"No" Button Text</Typography>
														<span
															aria-owns={
																anchorEl ? 'mouse-over-popover' : undefined
															}
															aria-haspopup='true'
															onMouseEnter={handlePopoverOpen}
															onMouseLeave={handlePopoverClose}
															style={{ height: '20px' }}
														>
															<HelpOutlineIcon
																id='No Button'
																sx={{ height: 18, color: 'lightgrey' }}
															/>
														</span>
													</Grid>
												</TableCell>
												<Popover
													id='mouse-over-popover'
													sx={{ pointerEvents: 'none' }}
													open={open}
													anchorEl={anchorEl}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'center',
													}}
													transformOrigin={{
														vertical: 'bottom',
														horizontal: 'center',
													}}
													onClose={handlePopoverClose}
													disableRestoreFocus
												>
													<Typography
														sx={{
															width: '300px',
															padding: '15px',
															backgroundColor: '#1f1f1f',
															color: 'white',
														}}
													>
														{btnPopupText(popYesNo)}
													</Typography>
												</Popover>
												<TableCell align='center'>
													<Typography>Edit Poll Settings</Typography>
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											<TableCell align='center'>
												<Typography>{date.toLocaleDateString()}</Typography>
											</TableCell>
											<TableCell align='center'>
												<YesBtnEditor
													modalOpen={yesBtnModalOpen}
													setModalOpen={setYesBtnModalOpen}
													pollData={pollData}
													id={id}
												/>
												<YesButton
													onClick={handleYesBtnModal}
													onMouseEnter={handleYesBtnHover}
													onMouseLeave={handleYesBtnLeave}
												>
													{yesButtonText}
												</YesButton>
											</TableCell>

											<TableCell align='center'>
												<NoBtnEditor
													modalOpen={noBtnModalOpen}
													setModalOpen={setNoBtnModalOpen}
													pollData={pollData}
													id={id}
												/>
												<NoButton
													onClick={handleNoBtnModal}
													onMouseEnter={handleNoBtnHover}
													onMouseLeave={handleNoBtnLeave}
												>
													{noButtonText}
												</NoButton>
											</TableCell>

											<TableCell align='center'>
												<PollTableEditor
													modalOpen={fullModalOpen}
													setModalOpen={setFullModalOpen}
													pollData={pollData}
													id={id}
												/>
												<Button
													variant='contained'
													size='small'
													onClick={handleFullModal}
												>
													EDIT POLL
												</Button>
											</TableCell>
										</TableBody>
									</Table>

									<TableRow className='flexColumn'>
										<Typography variant='h5' mb={1} mt={1}>
											Scripts
										</Typography>
										<div className='flexRowEven space-evenly'>
											<PollPageSnippet
												yesBtnText={yesBtnText}
												noBtnText={noBtnText}
												errorPage={errorRouting}
												id={id}
												yesBtnLink={yesBtnRouting}
												noBtnLink={noBtnRouting}
											/>
											<LoadingPageChart id={id} />
											<SocialProofTextEditor
												pollData={pollData}
												socialTextModalOpen={socialTextModalOpen}
												setSocialTextModalOpen={setSocialTextModalOpen}
											/>
											<SocialProofSnippet
												id={id}
												setSocialTextModalOpen={setSocialTextModalOpen}
												setEditSocialProofDialogOpen={
													setEditSocialProofDialogOpen
												}
											/>
										</div>
									</TableRow>

									<TableRow
										sx={{
											display: 'flex',
											justifyContent: 'space-around',
											width: '100%',
										}}
									>
										{autoForwardLink ? (
											<div>
												<RedirectSnippet
													redirectUrl={redirectRouting}
													redirectDelay={redirectDelay}
												/>
											</div>
										) : null}

										<div
											className='links-area'
											style={{
												display: 'flex',
												flexDirection: 'column',
												alignContent: 'center',
												alignItems: 'center',
												justifyContent: 'center',
											}}
										>
											<div>
												<TableCell>
													<Typography>
														Chart Link:{' '}
														<Link
															href={`/chart/${id}`}
															target='_blank'
															rel='noreferrer'
															sx={{ textDecoration: 'none' }}
														>
															{id}
														</Link>
													</Typography>
												</TableCell>
											</div>
											<div style={{ marginTop: '10px' }}>
												<Typography>
													Poll Page Link:{' '}
													<Link
														href={pollRouting}
														target='_blank'
														rel='noreferrer'
														sx={{ textDecoration: 'none' }}
													>
														{pollRouting}
													</Link>
												</Typography>

												{noBtnLink ? (
													<Typography>
														No Button Link:{' '}
														<Link
															href={noBtnRouting}
															target='_blank'
															rel='noreferrer'
															sx={{ textDecoration: 'none' }}
														>
															{noBtnRouting}
														</Link>
													</Typography>
												) : null}

												{yesBtnLink ? (
													<Typography>
														Yes Button Link:{' '}
														<Link
															href={yesBtnRouting}
															target='_blank'
															rel='noreferrer'
															sx={{ textDecoration: 'none' }}
														>
															{yesBtnRouting}
														</Link>
													</Typography>
												) : null}
											</div>

											{errorPageLink ? (
												<div>
													<Typography>
														Error Page link:{' '}
														<Link
															href={errorRouting}
															target='_blank'
															rel='noreferrer'
															sx={{ textDecoration: 'none' }}
														>
															{errorRouting}
														</Link>
													</Typography>
												</div>
											) : null}

											{autoForwardLink ? (
												<>
													<div>
														<Typography>
															Auto-forward Link:{' '}
															<Link
																href={redirectRouting}
																target='_blank'
																rel='noreferrer'
																sx={{ textDecoration: 'none' }}
															>
																{redirectRouting}
															</Link>
														</Typography>
													</div>
													<div>
														<Typography>
															Auto-forward Delay: {redirectDelay} seconds
														</Typography>
													</div>
												</>
											) : null}
											<div className='webhook-enable'>
												{webhookChecked ? (
													<FormControlLabel
														disabled={disableWebhookSwitch}
														control={
															<Switch
																size='small'
																checked={webhookChecked}
																onChange={handleWebhookSwitchChange}
															/>
														}
														label='Webhook Enabled'
													/>
												) : (
													<FormControlLabel
														disabled={disableWebhookSwitch}
														control={
															<Switch
																size='small'
																checked={webhookChecked}
																onChange={handleWebhookSwitchChange}
															/>
														}
														label='Webhook Disabled'
													/>
												)}
											</div>
											{webhookChecked ? (
												<div style={{ marginTop: '10px', width: '300px' }}>
													<Button
														size='small'
														variant='contained'
														onClick={handleCopyWebhook}
													>
														Copy Webhook
														<span
															className='button-icon'
															style={{ marginLeft: 10, marginTop: 5 }}
														>
															<Copy />
														</span>
													</Button>
												</div>
											) : null}
										</div>
									</TableRow>
									<div
										className='poll-chart'
										style={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}
									>
										<Divider sx={{ marginBottom: '20px' }} />

										<Chart id={id} title={pollName} showLink={checked} />

										<div className='margin-spacer'>
											<FormGroup>
												{disableSwitch ? (
													<>
														<FormControlLabel
															disabled={disableSwitch}
															control={
																<Switch
																	size='small'
																	checked={checked}
																	onChange={handleSwitchChange}
																/>
															}
															label='Show link on chart'
														/>
													</>
												) : (
													<div
														className='link-switch'
														style={{ display: 'flex' }}
													>
														<FormControlLabel
															disabled={disableSwitch}
															control={
																<Switch
																	size='small'
																	checked={checked}
																	onChange={handleSwitchChange}
																/>
															}
															label='Show link on chart'
														/>
														<EditChartLinkForm
															pollData={pollData}
															editPollModalOpen={editPollModalOpen}
															setEditPollModalOpen={setEditPollModalOpen}
															chartLink={chartLink}
															chartLinkText={chartLinkText}
														/>
														<EditIcon onClick={handleChartLinkEditModal} />
													</div>
												)}
											</FormGroup>
										</div>
									</div>
								</Collapse>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};

export default Poll;
