import { CircularProgress } from '@mui/material';
import { logout } from '../actions/userActions';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const Logout = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(logout());
	}, [dispatch]);

	return (
		<div>
			Logging out...
			<span>
				<CircularProgress />
			</span>
		</div>
	);
};

export default Logout;
