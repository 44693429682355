import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const UpgradeDialogModal = (props) => {
	const { dialogHeader, dialogOpen, handleDialogClose, modalWidth } = props;

	return (
		<Dialog open={dialogOpen} onClose={handleDialogClose}>
			<div className='upgrade-dialog' style={{ width: `${modalWidth}` }}>
				<DialogTitle>{dialogHeader}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Upgrade now to unlock additional polls and features to engage your
						audience.
					</DialogContentText>
				</DialogContent>
				<DialogActions
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '10px 20px',
					}}
				>
					<Button onClick={handleDialogClose}>No thanks</Button>
					<Link to='/my-account'>
						<Button onClick={handleDialogClose} variant='contained' autoFocus>
							UPGRADE NOW
						</Button>
					</Link>
				</DialogActions>
			</div>
		</Dialog>
	);
};

export default UpgradeDialogModal;
