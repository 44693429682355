import { axiosWithAuth } from '../utils/axiosWithAuth';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import EnhancedTable from '../components/elements/EnhancedTable';
import Header from '../components/header/Navbar';

const Admin = () => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [users, setUsers] = useState([]);

	useEffect(() => {
		document.title = `${process.env.REACT_APP_PAGE_TITLE} | Admin`;

		axiosWithAuth()
			.get(`/api/users/get-users`)
			.then((res) => {
				setUsers(res.data);
			})
			.then(() => setLoading(false))
			.catch((err) => {
				console.error(err);
				setError(err);
			});
	}, []);

	const descendingComparator = (a, b, orderBy) => {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	};

	const getComparator = (order, orderBy) => {
		return order === 'desc'
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	};

	const stableSort = (array, comparator) => {
		const stabilizedThis = array.map((el, index) => [el, index]);

		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	};

	return (
		<div className='dashboard'>
			<Header />
			<div className='poll-tables'>
				{loading ? (
					<div className='dashboard-loader'>
						<CircularProgress />
					</div>
				) : error ? (
					<Alert severity='error'>{error}</Alert>
				) : (
					<div className='poll-table-container'>
						<EnhancedTable
							users={users}
							stableSort={stableSort}
							getComparator={getComparator}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Admin;
