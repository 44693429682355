import { Link } from 'react-router-dom';
import { frontendEnv } from '../../utils/frontendEnv';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Logo from '../../images/logo.png';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const PasswordReset = ({ location }) => {
	const [userEmail, setUserEmail] = useState('');
	const [error, setError] = useState();
	const [message, setMessage] = useState();
	const [loading, setLoading] = useState();

	const { enqueueSnackbar } = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (data.password !== data.confirmPassword) {
			setMessage('Passwords do not match. Please try again.');
		} else {
			setLoading(true);
			axios
				.post(
					`${frontendEnv()}/api/users/update-password/${userId}/${userToken}`,
					data
				)
				.then((res) => {
					setLoading(false);
					if (res.status === 200) {
						enqueueSnackbar('Password updated', 'error');
						setMessage(
							<>
								<Link to='/login'>
									<Typography>Click here to sign in</Typography>
								</Link>
							</>
						);
					}
				})
				.catch((err) => {
					enqueueSnackbar(err, 'error');
				});
		}
	};

	const userId = location.pathname.split('/')[2];
	const userToken = location.pathname.split('/')[3];

	useEffect(() => {
		axios
			.get(`${frontendEnv()}/api/users/password-reset/${userId}/${userToken}`)
			.then((res) => {
				if (res.data === 'jwt expired') {
					setError(
						<>
							<Typography>Reset Token Expired!</Typography>
							<Link to='/reset-password'>
								<Typography>Click here to get a new token</Typography>
							</Link>
						</>
					);
				} else {
					setUserEmail(res.data.email);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [userId, userToken]);

	const paperStyle = {
		padding: 20,
		height: 'auto',
		width: 250,
		margin: '0 auto',
	};

	return (
		<>
			<div
				className='user-form'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					width: '100vw',
					height: '100vh',
				}}
			>
				{!userEmail ? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignContent: 'center',
							textAlign: 'center',
						}}
					>
						<Typography
							sx={{ color: '#fff', fontSize: '20px', marginBottom: '20px' }}
						>
							Password reset token has expired!
						</Typography>
						<Link to='/reset-password'>
							<Button variant='contained'>Get New Token</Button>
						</Link>
					</div>
				) : (
					<Grid className='register-form'>
						<Paper elevation={10} style={paperStyle}>
							<Grid align='center'>
								<CardContent>
									<div>
										<a href={frontendEnv()}>
											<img src={Logo} alt='PollMagic.io Logo' />
										</a>
									</div>
									<Typography variant='h5'>Reset Password</Typography>
								</CardContent>
								{message && <Alert severity='info'>{message}</Alert>}
								{error && <Alert severity='error'>{error}</Alert>}
								{loading && <CircularProgress />}
								<form onSubmit={handleSubmit(onSubmit)}>
									<TextField
										label='New Password'
										fullWidth
										margin='dense'
										size='small'
										name='password'
										type='password'
										{...register('password', {
											required: 'Password is required',
											pattern: {
												value:
													/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
												message:
													'Password must be at least 8 characters including at least one uppercase letter, one lowercase letter and one number.',
											},
										})}
										error={!!errors?.password}
										helperText={
											errors.password ? errors.password.message : null
										}
									/>
									<TextField
										label='Re-enter Password'
										fullWidth
										margin='dense'
										size='small'
										name='confirmPassword'
										type='password'
										{...register('confirmPassword', {
											required: true,
										})}
									/>
									<Button
										variant='contained'
										type='submit'
										fullWidth
										sx={{ margin: '10px auto' }}
									>
										SUBMIT PASSWORD
									</Button>
								</form>
								<div className='cancel'>
									<a href={frontendEnv()}>CANCEL</a>
								</div>
							</Grid>
						</Paper>
					</Grid>
				)}
			</div>
		</>
	);
};

export default PasswordReset;
