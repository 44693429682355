export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL';

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL';

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST';
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS';
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL';
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';

export const ADMIN_USER_UPDATE_PROFILE_REQUEST =
	'ADMIN_USER_UPDATE_PROFILE_REQUEST';
export const ADMIN_USER_UPDATE_PROFILE_SUCCESS =
	'ADMIN_USER_UPDATE_PROFILE_SUCCESS';
export const ADMIN_USER_UPDATE_PROFILE_FAIL = 'ADMIN_USER_UPDATE_PROFILE_FAIL';
export const ADMIN_USER_UPDATE_PROFILE_RESET =
	'ADMIN_USER_UPDATE_PROFILE_RESET';

export const ADMIN_SHOW_EDIT_BUTTON = 'ADMIN_SHOW_EDIT_BUTTON';

export const USER_POLLS_REQUEST = 'USER_POLLS_REQUEST';
export const USER_POLLS_REQUEST_SUCCESS = 'USER_POLLS_REQUEST_SUCCESS';
export const USER_POLLS_REQUEST_FAIL = 'USER_POLLS_REQUEST_FAIL';
