import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';

const EnhancedTableHead = (props) => {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const headCells = [
		{
			id: 'name',
			numeric: false,
			disablePadding: true,
			label: 'Name',
		},
		{
			id: 'email',
			numeric: false,
			disablePadding: true,
			label: 'Email',
		},
		{
			id: 'stripeid',
			numeric: false,
			disablePadding: true,
			label: 'Stripe ID',
		},
		{
			id: 'subscription',
			numeric: false,
			disablePadding: true,
			label: 'Subscription',
		},
		// {
		// 	id: 'spacerCell',
		// 	numeric: false,
		// 	disablePadding: true,
		// 	label: '',
		// },
	];

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all users',
						}}
					/>
				</TableCell> */}
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? 'right' : 'center'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default EnhancedTableHead;
