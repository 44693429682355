import { Link, useLocation, useNavigate } from 'react-router-dom';
import { frontendEnv } from '../../utils/frontendEnv';
import { registerUser } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Logo from '../../images/logo.png';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CheckIcon from '@mui/icons-material/Check';

const UserRegisterForm = ({ history }) => {
	document.title = 'Poll Magic | Register for Free';
	const [message, setMessage] = useState();

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (data.password !== data.confirmPassword) {
			setMessage('Passwords do not match. Please try again.');
			return;
		} else {
			dispatch(
				registerUser(data.firstName, data.lastName, data.email, data.password)
			);
		}
	};

	const userRegister = useSelector((state) => state.userRegister);
	const { loading, error, userInfo } = userRegister;

	const redirect = location.search ? location.search.split('=')[1] : '/';

	useEffect(() => {
		if (userInfo) {
			navigate('/dashboard');
		}
	}, [navigate, userInfo, redirect]);

	const paperStyle = {
		padding: 20,
		height: 'auto',
		width: 600,
		margin: '0 auto',
	};

	return (
		<div className='user-form'>
			<Grid className='register-form'>
				<Paper elevation={10} style={paperStyle}>
					<Grid align='center'>
						<CardContent>
							<div>
								<a href={frontendEnv()}>
									<img src={Logo} alt='Logo' />
								</a>
							</div>
							<Typography variant='h3'>Create A Free Account</Typography>
							<Typography className='register-subhead'>
								<span className='register-bold'>
									Create a free account now{' '}
								</span>
								to build your email list and drive more sales and leads for your
								business from day one.
							</Typography>
							<div className='checkits'>
								<div className='check-item'>
									<span>
										<CheckIcon fontSize='large' />
									</span>
									<Typography variant='h6'>
										Forever free plan available
									</Typography>
								</div>
								<div className='check-item'>
									<span>
										<CheckIcon fontSize='large' />
									</span>
									<Typography variant='h6'>Set up in minutes</Typography>
								</div>
							</div>

							{message && <Alert severity='info'>{message}</Alert>}
							{error && <Alert severity='error'>{error}</Alert>}
							{loading && <CircularProgress />}
							<form onSubmit={handleSubmit(onSubmit)}>
								<TextField
									label='First Name'
									placeholder='First Name'
									fullWidth
									margin='dense'
									size='small'
									name='firstName'
									type='text'
									{...register('firstName', {
										required: 'First name is required',
									})}
									error={!!errors?.firstName}
									helperText={
										errors.firstName ? errors.firstName.message : null
									}
								/>
								<TextField
									label='Last Name'
									fullWidth
									margin='dense'
									size='small'
									name='lastName'
									type='text'
									{...register('lastName', {
										required: 'Last name is required',
									})}
									error={!!errors?.lastName}
									helperText={errors.lastName ? errors.lastName.message : null}
								/>
								<TextField
									inputProps={{
										autoComplete: 'username',
									}}
									label='Email'
									fullWidth
									margin='dense'
									size='small'
									name='email'
									type='email'
									{...register('email', {
										required: 'Email is required',
										pattern: {
											value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
											message: 'Invalid email address',
										},
									})}
									error={!!errors?.email}
									helperText={errors.email ? errors.email.message : null}
								/>
								<TextField
									inputProps={{
										autoComplete: 'new-password',
									}}
									label='Password'
									fullWidth
									margin='dense'
									size='small'
									name='password'
									type='password'
									{...register('password', {
										required: 'Password is required',
										pattern: {
											value:
												/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
											message:
												'Password must be at least 8 characters including at least one uppercase letter, one lowercase letter and one number.',
										},
									})}
									error={!!errors?.password}
									helperText={errors.password ? errors.password.message : null}
								/>
								<TextField
									inputProps={{
										autoComplete: 'new-password',
									}}
									label='Re-enter Password'
									fullWidth
									margin='dense'
									size='small'
									name='confirmPassword'
									type='password'
									{...register('confirmPassword', {
										required: true,
									})}
								/>

								<Button
									variant='contained'
									type='submit'
									fullWidth
									sx={{ margin: '5px auto 30px' }}
								>
									Get Started
								</Button>
							</form>
							<Link
								className='signin-link'
								to={redirect ? `/login?redirect=${redirect}` : '/login'}
							>
								<Typography>
									Have An Account?{' '}
									<span className='register-bold'>Sign in.</span>
								</Typography>
							</Link>
						</CardContent>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
};

export default UserRegisterForm;
