const Chart = ({ id, pollName, showLink }) => {
	return (
		<div>
			<iframe
				key={showLink}
				src={`/chart/${id}`}
				title={pollName}
				width={900}
				height={245}
			/>
		</div>
	);
};

export default Chart;
