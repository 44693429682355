import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BaseUrlRedirect = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate('/dashboard');
	}, [navigate]);

	return <></>;
};

export default BaseUrlRedirect;
