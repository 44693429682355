import { axiosWithAuth } from '../utils/axiosWithAuth';
import {
	livePlans,
	testPlans,
} from '../components/stripe/data/subscriptionPrices';
import { logout, updateUserProfile } from '../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const Success = () => {
	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const [disabled, setDisabled] = useState(true);
	const [invoice, setInvoice] = useState();

	// const { enqueueSnackbar } = useSnackbar();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const planType = userInfo.subscription;

	const logUserOut = () => {
		dispatch(logout);
		navigate('/login');
		window.location.reload();
	};

	const paymentIntent = new URLSearchParams(window.location.search).get(
		'payment_intent'
	);

	useEffect(() => {
		const databaseUpdate = (newPlan, subStart, subEnd) => {
			dispatch(
				updateUserProfile({
					id: userInfo._id,
					...userInfo,
					subscription: newPlan.description,
					subscriptionInterval: newPlan.interval,
					subscriptionStart: subStart,
					subscriptionEnd: subEnd,
				})
			);
			setDisabled(false);
		};

		const setNewPlan = (id, subStart, subEnd) => {
			let newPlan;

			if (process.env.NODE_ENV === 'Production') {
				newPlan = livePlans.find((plan) => plan.id === id);
			} else {
				newPlan = testPlans.find((plan) => plan.id === id);
			}

			databaseUpdate(newPlan, subStart, subEnd);
		};

		const getInvoice = (invoice) => {
			axiosWithAuth()
				.get(`/api/checkout/get-invoice/${invoice}`)
				.then((res) => {
					console.log(res);
					const priceId = res.data.lines.data[0].price.id;
					const subscriptionStart = `${res.data.lines.data[0].period.start}000`;
					const subscriptionEnd = `${res.data.lines.data[0].period.end}000`;
					setNewPlan(priceId, subscriptionStart, subscriptionEnd);
				})
				.catch((err) => console.error(err));
		};

		const setDefaultPaymentMethod = ({ id, paymentMethod }) => {
			axiosWithAuth()
				.post(`/api/checkout/update-user-default-payment/${id}`, {
					paymentMethod,
				})
				.then((res) => {
					console.log(res);
					getInvoice(invoice);
				})
				.catch((err) => console.error(err));
		};

		axiosWithAuth()
			.get(`/api/checkout/payment-intent/${paymentIntent}`)
			.then((res) => {
				console.log(res.data);
				setInvoice(res.data.invoice);
				setDefaultPaymentMethod({
					id: userInfo.stripeId,
					paymentMethod: res.data.payment_method,
				});
			})
			.catch((err) => console.error(err));
	}, [paymentIntent, planType, dispatch, userInfo, invoice]);

	return (
		<div className='success-page'>
			<Typography variant='h2'>Success</Typography>
			<Typography variant='h5'>
				Click button below to enable new plan
			</Typography>
			<Button variant='contained' disabled={disabled} onClick={logUserOut}>
				Apply New Features
			</Button>
		</div>
	);
};

export default Success;
