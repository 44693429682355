import AddchartIcon from '@mui/icons-material/Addchart';
import Fab from '@mui/material/Fab';

const AddPollBtn = ({ handleDialogOpen }) => {
	const addPollBtnStyle = {
		bgcolor: '#228bf3',
		color: '#fff',
		marginBottom: '20px',
		borderRadius: '3px',
		'&:hover': {
			bgcolor: '#2078cf',
		},
	};

	return (
		<Fab
			variant='extended'
			size='medium'
			onClick={handleDialogOpen}
			sx={addPollBtnStyle}
		>
			Add New Poll <AddchartIcon sx={{ ml: 1 }} />
		</Fab>
	);
};

export default AddPollBtn;
