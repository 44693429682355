import { axiosWithAuth } from '../utils/axiosWithAuth';
import { listUserPolls } from '../actions/pollActions';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useNavigate } from 'react-router-dom';
import AddchartIcon from '@mui/icons-material/Addchart';
import AddPollBtn from '../components/buttons/AddPollBtn';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import EditChartLinkForm from '../components/elements/EditChartLinkForm';
import Fab from '@mui/material/Fab';
import Header from '../components/header/Navbar';
import NewPollForm from '../components/newPollForm/NewPollForm';
import Poll from '../components/pollTable/Poll';
import Pollers from '../images/pollers.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import UpgradeDialogModal from '../components/elements/UpgradeDialogModal';

const Dashboard = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [addPollModalOpen, setAddPollModalOpen] = useState(false);
	const [editChartLinkDialogOpen, setEditChartLinkDialogOpen] = useState(false);
	const [editSocialProofDialogOpen, setEditSocialProofDialogOpen] =
		useState(false);
	const [initialSort, setInitialSort] = useState();
	const [pollDialogOpen, setPollDialogOpen] = useState(false);

	const handleDialogOpen = () => {
		setPollDialogOpen(true);
	};

	const handleDialogClose = () => {
		setPollDialogOpen(false);
	};

	const handleSocialProofDialogClose = () => {
		setEditSocialProofDialogOpen(false);
	};

	const handleEditChartLinkClose = () => {
		setEditChartLinkDialogOpen(false);
	};

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const pollList = useSelector((state) => state.pollList);
	const { loading, error, polls } = pollList;
	const [sortedPolls, setSortedPolls] = useState([]);

	// set poll list order
	useEffect(() => {
		// sort poll list by sortPosition number
		polls.sort((a, b) => {
			return a.sortPosition - b.sortPosition;
		});

		setSortedPolls(polls);
		setInitialSort(polls);
	}, [polls]);

	const handleDeletePoll = (id) => {
		axiosWithAuth()
			.delete(`/api/polls/${id}`)
			.then(() => {
				dispatch(listUserPolls(userInfo._id));
			})
			.catch((err) => {
				console.error(err);
			});
	};

	// Removes extra polls when downgrading
	useEffect(() => {
		// Set up additional plan scenarios
		if (userInfo.subscription === 'Free' && polls.length > 1) {
			const shuffleArray = (arr) => {
				for (let i = arr.length - 1; i > 0; i--) {
					let j = Math.floor(Math.random() * (i + 1));

					[arr[i], arr[j]] = [arr[j], arr[i]];

					return arr;
				}
			};

			let deletePolls = shuffleArray(polls);

			const removeRandomArrayIndex = () => {
				let randomIndex = Math.floor(Math.random() * deletePolls.length);

				return deletePolls.splice(randomIndex, 1)[0];
			};

			// const savePoll = removeRandomArrayIndex();
			removeRandomArrayIndex();

			for (let i = 0; i < deletePolls.length - 1; i++) {
				handleDeletePoll(deletePolls[i]._id);
			}
		}
		// eslint-disable-next-line
	}, [dispatch, polls, userInfo]);

	const handleFullModal = () => {
		setAddPollModalOpen(!addPollModalOpen);
	};

	// Fetches polls
	useEffect(() => {
		if (userInfo) {
			document.title = `${process.env.REACT_APP_PAGE_TITLE} | Dashboard`;
			navigate('/dashboard');
			dispatch(listUserPolls(userInfo._id));
		} else {
			navigate('/login');
		}
	}, [dispatch, userInfo, navigate]);

	const dragTable = useRef();
	const dragOverItem = useRef();

	const dragStart = (e, position) => {
		dragTable.current = position;
		setInitialSort(sortedPolls);
	};

	const dragEnter = (e, position) => {
		dragOverItem.current = position;
	};

	const updateSortPositions = (sort) => {
		// Check to see if sortPosition has changed
		// console.log(sort === initialSort);
		// console.log(initialSort);
		// console.log(sort);

		// this isn't working
		let sortCheck = true;
		let i;
		for (i = 0; i < sort.length; i++) {
			if (sort[1]._id !== initialSort[i]._id) {
				sortCheck = false;
				i = sort.length;
			} else {
				continue;
			}
		}

		// console.log(sortCheck);
		if (sortCheck === false) {
			// update poll's sortPosition in DB
			for (let i = 0; i < sort.length; i++) {
				let data = { ...sort[i], sortPosition: i + 1 };
				axiosWithAuth()
					.put(`/api/polls/${sort[i]._id}`, data)
					.then((res) => console.log(res.status))
					.catch((err) => console.error(err));
			}
		} else {
			return;
		}
	};

	const dropTable = () => {
		const copyPolls = [...sortedPolls];
		const dragTableContent = copyPolls[dragTable.current];
		copyPolls.splice(dragTable.current, 1);
		copyPolls.splice(dragOverItem.current, 0, dragTableContent);
		dragTable.current = null;
		dragOverItem.current = null;
		setSortedPolls(copyPolls);
		// update DB sortPosition with new positions
		updateSortPositions(copyPolls);
	};

	const addPollBtnStyle = {
		bgcolor: '#228bf3',
		color: '#fff',
		marginBottom: '20px',
		borderRadius: '3px',
		'&:hover': {
			bgcolor: '#2078cf',
		},
	};

	if (!sortedPolls.length)
		return (
			<div className='dashboard-container'>
				<Header />
				<div className='dashboard'>
					{loading ? (
						<div className='dashboard-loader'>
							<CircularProgress />
						</div>
					) : error ? (
						<Alert severity='error'>{error}</Alert>
					) : (
						<div className='first-poll-container'>
							<div className='new-post-img'>
								<img src={Pollers} alt='create new poll' />
							</div>

							<Typography variant='h2'>Create Your First Poll</Typography>
							<div className='add-chart-button'>
								<Fab
									variant='extended'
									size='medium'
									onClick={handleFullModal}
									sx={addPollBtnStyle}
								>
									<Typography>Add New Poll</Typography>
									<AddchartIcon sx={{ ml: 1 }} />
								</Fab>
							</div>
							<NewPollForm
								addPollModalOpen={addPollModalOpen}
								setAddPollModalOpen={setAddPollModalOpen}
							/>
						</div>
					)}
				</div>
			</div>
		);

	return (
		<div className='dashboard'>
			<Header />
			<div className='poll-tables'>
				{loading ? (
					<div className='dashboard-loader'>
						<CircularProgress />
					</div>
				) : error ? (
					<Alert severity='error'>{error}</Alert>
				) : (
					<div className='poll-table-container' id='poll-sort-container'>
						{sortedPolls.map((poll, index) => {
							return (
								<Table
									draggable
									key={poll._id}
									onDragEnd={dropTable}
									className='table-container'
									onDragOver={(e) => e.preventDefault()}
									onDragStart={(e) => dragStart(e, index)}
									onDragEnter={(e) => dragEnter(e, index)}
								>
									<TableBody className='table-body'>
										<Poll
											id={poll._id}
											autoForwardLink={poll.autoForwardLink}
											autoForwardPath={poll.autoForwardPath}
											autoForwardProtocol={poll.autoForwardProtocol}
											chartLink={poll.chartLink}
											chartLinkText={poll.chartLinkText}
											createdDate={poll.createdAt}
											errorPageLink={poll.errorPageLink}
											errorPagePath={poll.errorPagePath}
											errorProtocol={poll.errorProtocol}
											handleDeletePoll={handleDeletePoll}
											noBtnText={poll.noBtnText}
											noBtnProtocol={poll.noBtnProtocol}
											noBtnLink={poll.noBtnLink}
											noBtnPath={poll.noBtnPath}
											noVotes={poll.noVotes}
											pollId={poll.pollId}
											pollName={poll.pollName}
											pollPageLink={poll.pollPageLink}
											pollPagePath={poll.pollPagePath}
											protocol={poll.protocol}
											redirectDelay={poll.redirectDelay}
											setEditChartLinkDialogOpen={setEditChartLinkDialogOpen}
											setEditSocialProofDialogOpen={
												setEditSocialProofDialogOpen
											}
											showChartLink={poll.showChartLink}
											socialProofText={poll.socialProofText}
											totalVotes={poll.totalVotes}
											yesBtnText={poll.yesBtnText}
											yesBtnProtocol={poll.yesBtnProtocol}
											yesBtnLink={poll.yesBtnLink}
											yesBtnPath={poll.yesBtnPath}
											yesVotes={poll.yesVotes}
										/>
									</TableBody>
								</Table>
							);
						})}
					</div>
				)}
				<div className='add-chart-icon' style={{ marginTop: '20px' }}>
					{(polls.length === 1 &&
						(userInfo.subscription === 'Free' ||
							userInfo.subscription === 'Basic' ||
							userInfo.subscription === 'Bonus')) ||
					(polls.length === 5 &&
						(userInfo.subscription === 'Pro' ||
							userInfo.subscription === 'Beta')) ||
					(polls.length === 20 && userInfo.subscription === 'Ultimate') ||
					(polls.length === 50 && userInfo.subscription === 'Agency') ? (
						<AddPollBtn handleDialogOpen={handleDialogOpen} />
					) : (
						<Fab
							variant='extended'
							size='medium'
							onClick={handleFullModal}
							sx={addPollBtnStyle}
						>
							Add New Poll <AddchartIcon sx={{ ml: 1 }} />
						</Fab>
					)}
				</div>
				<NewPollForm
					addPollModalOpen={addPollModalOpen}
					setAddPollModalOpen={setAddPollModalOpen}
				/>
				<EditChartLinkForm
					editChartLinkDialogOpen={editChartLinkDialogOpen}
					setEditChartLinkDialogOpen={setEditChartLinkDialogOpen}
				/>
			</div>
			<UpgradeDialogModal
				dialogHeader={"You've reached the poll limit for your current plan!"}
				dialogOpen={pollDialogOpen}
				handleDialogClose={handleDialogClose}
				modalWidth='500px'
			/>
			<UpgradeDialogModal
				dialogHeader={
					'Editing social proof text is not included in the Free plan!'
				}
				dialogOpen={editSocialProofDialogOpen}
				handleDialogClose={handleSocialProofDialogClose}
				modalWidth='560px'
			/>
			<UpgradeDialogModal
				dialogHeader={
					'Editing the chart link is not included in the Free plan!'
				}
				dialogOpen={editChartLinkDialogOpen}
				handleDialogClose={handleEditChartLinkClose}
				modalWidth='560px'
			/>
		</div>
	);
};

export default Dashboard;
