import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { pollListReducer, pollDetailsReducer } from './reducers/pollReducers';
import {
	userDeleteReducer,
	userDetailsReducer,
	userListReducer,
	userLoginReducer,
	userRegisterReducer,
	userUpdateProfileReducer,
	userPollsReducer,
} from './reducers/userReducers';
import thunk from 'redux-thunk';

const reducer = combineReducers({
	pollList: pollListReducer,
	pollDetails: pollDetailsReducer,
	userDelete: userDeleteReducer,
	userDetails: userDetailsReducer,
	userList: userListReducer,
	userLogin: userLoginReducer,
	userRegister: userRegisterReducer,
	userUpdateProfile: userUpdateProfileReducer,
	userPolls: userPollsReducer,
});

const userInfoFromStorage = localStorage.getItem('userInfo')
	? JSON.parse(localStorage.getItem('userInfo'))
	: null;

const pollsListFromStorage = localStorage.getItem('userPolls')
	? JSON.parse(localStorage.getItem('userPolls'))
	: null;

const initialState = {
	userLogin: { userInfo: userInfoFromStorage },
	userPolls: { userInfo: pollsListFromStorage },
};

const middleware = [thunk];

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
