import { axiosWithAuth } from '../utils/axiosWithAuth';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Header from '../components/header/Navbar';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useNavigate } from 'react-router-dom';

const UserProfile = () => {
	const [confirmPassword, setConfirmPassword] = useState('');
	const [currentPassword, setCurrentPassword] = useState('');
	const [email, setEmail] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [loading, setLoading] = useState(true);
	const [password, setPassword] = useState('');
	const [passwordChangeDisable, setPasswordChangeDisable] = useState(true);
	const [showDeleteControls, setShowDeleteControls] = useState(false);
	const [updateInProgress, setUpdateInProgress] = useState(false);
	const [updateResult, setUpdateResult] = useState(false);

	const navigate = useNavigate();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const passwordCheck = (data) => {
		const submitData = {
			email,
			password: data.toString(),
		};

		const searchUser = () => {
			axiosWithAuth()
				.post(`/api/users/verify-pw`, submitData)
				.then((res) => {
					if (res.data.email === userInfo.email) {
						console.log(res.data);
						setPasswordChangeDisable(false);
					}
				})
				.catch(() => {
					setPasswordChangeDisable(true);
				});
		};

		setCurrentPassword(data);
		searchUser(userInfo._id);
	};

	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		document.title = `${process.env.REACT_APP_PAGE_TITLE} | Profile`;
		if (!userInfo) {
			navigate('/login');
		} else {
			axiosWithAuth()
				.get(`/api/users/profile/${userInfo._id}`)
				.then((res) => {
					setFirstName(res.data.firstName);
					setLastName(res.data.lastName);
					setEmail(res.data.email);
				})
				.then(() => setLoading(false))
				.catch((err) => console.error(err));
		}
	}, [navigate, userInfo]);

	const submitHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			enqueueSnackbar('Passwords do not match', 'error');
		} else {
			const updatedUser = {
				id: userInfo._id,
				firstName,
				lastName,
				email,
				password,
			};

			setUpdateInProgress(true);
			axiosWithAuth()
				.put(`/api/users/profile`, updatedUser)
				.then(() => {
					setUpdateResult(true);
					setUpdateInProgress(false);
					enqueueSnackbar('Profile Updated', 'info');
				})
				.then(() => {
					setTimeout(() => {
						setUpdateResult(false);
					}, 3000);
				})
				.catch(() => enqueueSnackbar('Profile Not Updated', 'error'));
		}
	};

	const handleCancel = () => {
		navigate('/dashboard');
	};

	const handleDelete = (id) => {
		axiosWithAuth()
			.delete(`/api/users/delete-user/${id}`)
			.then(() => {
				navigate('/logout');
			})
			.catch((err) => console.error(err));
	};

	const inputStyle = {
		margin: '5px auto',
		backgroundColor: 'white',
	};

	const submitBtnStyle = {
		marginTop: '10px',
		bgcolor: '#1976d2',
		color: 'rgb(255, 255, 255)',
		fontSize: '1rem',
		'&:hover': {
			bgcolor: '#1b60a5',
		},
	};

	return (
		<>
			<Header />
			<div className='profile'>
				<Grid className='add-user'>
					<Grid align='center'>
						<div style={{ marginBottom: '20px' }}></div>
						<Typography variant='h5' id='add-user-h2' sx={{ color: 'white' }}>
							User Profile
						</Typography>
					</Grid>

					{loading ? (
						<CircularProgress />
					) : (
						<form className='profile-form' onSubmit={submitHandler}>
							<Typography sx={{ color: 'white' }}>Update User Info</Typography>
							<TextField
								style={inputStyle}
								placeholder='Enter First Name'
								variant='outlined'
								size='small'
								fullWidth
								name='firstName'
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
							/>

							<TextField
								style={inputStyle}
								placeholder='Enter Last Name'
								variant='outlined'
								size='small'
								fullWidth
								name='lastName'
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>

							<TextField
								inputProps={{
									autoComplete: 'username',
								}}
								style={inputStyle}
								placeholder='Enter User Email'
								variant='outlined'
								size='small'
								fullWidth
								name='email'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>

							<Typography sx={{ color: 'white', marginTop: '20px' }}>
								Update Password
							</Typography>

							<TextField
								inputProps={{
									autoComplete: 'new-password',
								}}
								style={inputStyle}
								placeholder='Enter Current Password'
								variant='outlined'
								size='small'
								fullWidth
								name='currentPassword'
								type='password'
								value={currentPassword}
								onChange={(e) => passwordCheck(e.target.value)}
							/>

							<TextField
								inputProps={{
									autoComplete: 'new-password',
								}}
								style={inputStyle}
								placeholder='Enter New Password'
								variant='outlined'
								size='small'
								fullWidth
								name='password'
								type='password'
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								disabled={passwordChangeDisable}
							/>

							<TextField
								inputProps={{
									autoComplete: 'new-password',
								}}
								style={inputStyle}
								placeholder='Confirm New Password'
								variant='outlined'
								size='small'
								fullWidth
								name='confirmPassword'
								type='password'
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								disabled={passwordChangeDisable}
							/>

							<div className='profile-buttons'>
								<Button onClick={handleCancel}>Cancel</Button>
								{updateInProgress ? (
									<Button
										style={submitBtnStyle}
										color='primary'
										variant='contained'
									>
										<CircularProgress sx={{ color: '#fff' }} size={27} />
									</Button>
								) : updateResult ? (
									<Button
										style={submitBtnStyle}
										color='success'
										variant='contained'
									>
										<CheckCircleOutlineIcon
											sx={{ color: '#fff' }}
											fontSize='medium'
										/>
									</Button>
								) : (
									<Button
										style={submitBtnStyle}
										type='submit'
										color='primary'
										variant='contained'
									>
										Update
									</Button>
								)}
							</div>
						</form>
					)}
				</Grid>
				<Grid className='danger-zone'>
					<Accordion className='danger-accordion'>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel1a-content'
							id='panel1a-header'
						>
							<Typography>DANGER ZONE!</Typography>
						</AccordionSummary>
						<AccordionDetails className='danger-accordion-details'>
							{showDeleteControls && !userInfo.isAdmin ? (
								<>
									<Typography>Are you sure?</Typography>
									<Button
										variant='outlined'
										color='error'
										onClick={() => setShowDeleteControls(false)}
									>
										No
									</Button>
									<Button
										variant='contained'
										color='error'
										onClick={() => handleDelete(userInfo.stripeId)}
									>
										Yes
									</Button>
								</>
							) : (
								<Button
									variant='outlined'
									color='error'
									onClick={() => setShowDeleteControls(true)}
								>
									Delete My Account
								</Button>
							)}
						</AccordionDetails>
					</Accordion>
				</Grid>
			</div>
		</>
	);
};

export default UserProfile;
