import { axiosWithAuth } from '../../utils/axiosWithAuth';
import { listUserPolls } from '../../actions/pollActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHandleForm } from '../../hooks/useHandleForm';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const NoBtnEditor = ({ modalOpen, pollData, setModalOpen }) => {
	const dispatch = useDispatch();

	const initialFormData = {
		pollName: pollData.pollName,
		yesVotes: pollData.yesVotes,
		noVotes: pollData.noVotes,
		yesBtnText: pollData.yesBtnText,
		noBtnText: pollData.noBtnText,
		pollPageLink: pollData.pollPageLink,
		errorPageLink: pollData.errorPageLink,
	};

	const [noBtnForm, setNoBtnForm, handleChange] =
		useHandleForm(initialFormData);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const handleCancel = () => {
		setNoBtnForm(initialFormData);
		setModalOpen(false);
	};

	const data = {
		...pollData,
		noBtnText: noBtnForm.noBtnText,
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		axiosWithAuth()
			.put(`/api/polls/${pollData.id}`, data)
			.then(() => {
				dispatch(listUserPolls(userInfo._id));
				setModalOpen(false);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	return (
		<>
			<Dialog open={modalOpen} onClose={handleCancel}>
				<DialogTitle>Edit {pollData.pollName}</DialogTitle>
				<DialogContent>
					<form>
						<TextField
							label='No Button text'
							fullWidth
							margin='dense'
							size='small'
							placeholder='Edit No Button text'
							value={noBtnForm.noBtnText}
							name='noBtnText'
							onChange={(e) => handleChange(e.target)}
						/>
						<Button
							type='submit'
							onClick={handleSubmit}
							fullWidth
							variant='contained'
							disabled={noBtnForm.noBtnText === initialFormData.noBtnText}
						>
							SUBMIT
						</Button>
					</form>
					<Grid align='right'>
						<Button
							onClick={handleCancel}
							variant='text'
							size='small'
							margin='dense'
						>
							Cancel
						</Button>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default NoBtnEditor;
