import { frontendEnv } from '../../utils/frontendEnv';
import { login } from '../../actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Logo from '../../images/logo.png';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const UserLoginForm = ({ history }) => {
	const [signInForm, setSignInForm] = useState({
		email: '',
		password: '',
	});

	document.title = 'Poll Magic | Login';

	const handleChange = (e, type) => {
		setSignInForm({ ...signInForm, [type]: e.target.value });
	};

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const userLogin = useSelector((state) => state.userLogin);
	const { loading, error, userInfo } = userLogin;

	const redirect = location.search ? location.search.split('=')[1] : '/';

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, userInfo, redirect]);

	const submitHandler = (e) => {
		e.preventDefault();
		dispatch(login(signInForm.email, signInForm.password));
	};

	const paperStyle = {
		padding: 10,
		height: 'auto',
		width: 500,
		margin: '20px auto',
	};

	return (
		<div className='user-form'>
			<Grid className='login-form'>
				<Paper elevation={10} style={paperStyle}>
					<Grid align='center'>
						<CardContent>
							<div>
								<a href={frontendEnv()}>
									<img src={Logo} alt='Logo' />
								</a>
							</div>
							<Typography variant='h3'>Sign In</Typography>
							{error && (
								<Alert severity='error'>
									<Typography>
										Check entered email and password for errors
									</Typography>
								</Alert>
							)}
							{loading && <CircularProgress />}
							<form onSubmit={submitHandler}>
								<TextField
									inputProps={{
										autoComplete: 'on',
									}}
									size='small'
									fullWidth
									margin='dense'
									required
									type='email'
									label='Email'
									name='email'
									onChange={(e) => handleChange(e, 'email')}
									value={signInForm.email}
									autoFocus
								/>
								<TextField
									inputProps={{
										autoComplete: 'new-password',
									}}
									size='small'
									fullWidth
									margin='dense'
									required
									type='password'
									label='Password'
									name='password'
									onChange={(e) => handleChange(e, 'password')}
									value={signInForm.password}
								/>
								<Grid align='left'></Grid>
								<Button
									type='submit'
									variant='contained'
									fullWidth
									sx={{ margin: '10px auto' }}
								>
									Login
								</Button>
							</form>
							<Grid className='card-actions'>
								<Link
									to={redirect ? `/register?redirect=${redirect}` : '/register'}
								>
									<Typography>Not A Member? Register</Typography>
								</Link>
								<Link to='/reset-password'>
									<Typography>Forgot password?</Typography>
								</Link>
							</Grid>
						</CardContent>
					</Grid>
				</Paper>
			</Grid>
		</div>
	);
};

export default UserLoginForm;
