import { axiosWithAuth } from '../../utils/axiosWithAuth';
import { listUserPolls } from '../../actions/pollActions';
import { useHandleForm } from '../../hooks/useHandleForm';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Typography from '@mui/material/Typography';

const SocialProofTextEditor = (props) => {
	const dispatch = useDispatch();
	const { pollData, socialTextModalOpen, setSocialTextModalOpen } = props;

	const initialFormData = {
		socialProofText: pollData.socialProofText,
	};

	const [socialTextForm, setSocialTextForm, handleChange] =
		useHandleForm(initialFormData);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const handleCancel = () => {
		setSocialTextForm(initialFormData);
		setSocialTextModalOpen(false);
	};

	const data = {
		...pollData,
		socialProofText: socialTextForm.socialProofText,
	};

	const handleSubmit = () => {
		axiosWithAuth()
			.put(`/api/polls/${pollData.id}`, data)
			.then(() => {
				dispatch(listUserPolls(userInfo._id));
				setSocialTextModalOpen(false);
			})
			.catch((err) => console.error(err));
	};

	return (
		<>
			<Dialog open={socialTextModalOpen} onClose={handleCancel}>
				<DialogTitle>Social Proof Text Editor</DialogTitle>
				<DialogContent>
					<Typography>
						Insert &#123;%VOTES&#125; where you would like to display the total
						submitted votes.
					</Typography>
					<Typography style={{ fontWeight: 'bold' }}>
						Current total submitted votes:{' '}
						{pollData.totalVotes.toLocaleString()}
					</Typography>
					<br />
					<Typography style={{ fontWeight: 'bold' }}>
						Current social proof text:
					</Typography>
					<Typography>
						{initialFormData.socialProofText.replaceAll(
							'{%VOTES}',
							pollData.totalVotes.toLocaleString()
						)}
					</Typography>
				</DialogContent>
				<DialogContent>
					<form>
						<TextareaAutosize
							minRows={3}
							style={{
								width: '98.5%',
								marginBottom: 10,
								fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
								fontWeight: 400,
								fontSize: 16,
								padding: 5,
							}}
							name='socialProofText'
							value={socialTextForm.socialProofText}
							onChange={(e) => handleChange(e.target)}
						/>
						<Button
							type='submit'
							onClick={handleSubmit}
							fullWidth
							variant='contained'
							disabled={
								socialTextForm.socialProofText ===
								initialFormData.socialProofText
							}
						>
							SUBMIT
						</Button>
					</form>
					<Grid align='right' mt={1.5}>
						<Button
							onClick={handleCancel}
							variant='text'
							size='small'
							margin='dense'
						>
							Cancel
						</Button>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default SocialProofTextEditor;
