import { Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Admin from './screens/Admin';
import AdminRoute from './middleware/AdminRoute';
import Background from './components/elements/Background';
import Chart from './components/pollTable/Chart';
import CheckoutPage from './screens/CheckoutPage';
import Dashboard from './screens/Dashboard';
import ForgotPassword from './components/elements/ForgotPassword';
import Logout from './screens/Logout';
import MyAccount from './screens/MyAccount';
import PasswordReset from './components/elements/PasswordReset';
import PrivateRoute from './middleware/PrivateRoute';
import Success from './screens/Success';
import TotalVotes from './components/pollTable/TotalVotes';
import UpdateSuccess from './screens/UpdateSuccess';
import UserLoginForm from './components/elements/UserLoginForm';
import UserProfile from './screens/UserProfile';
import UserRegisterForm from './components/elements/UserRegisterForm';
import './styles/main.css';
import BaseUrlRedirect from './screens/BaseUrlRedirect';
import Tools from './screens/Tools';

function App() {
	return (
		<SnackbarProvider maxSnack={3}>
			<Routes>
				<Route path='/login' element={<UserLoginForm />} />
				<Route path='/reset-password' element={<ForgotPassword />} />
				<Route path='/password-reset/:id/:token' element={<PasswordReset />} />
				<Route path='/register' element={<UserRegisterForm />} />
				<Route
					path='/admin'
					element={
						<AdminRoute>
							<Admin />
						</AdminRoute>
					}
				/>
				<Route
					path='/logout'
					element={
						<PrivateRoute>
							<Logout />
						</PrivateRoute>
					}
				/>
				<Route
					path='/checkout'
					element={
						<PrivateRoute>
							<CheckoutPage />
						</PrivateRoute>
					}
				/>
				<Route
					path='/my-account'
					element={
						<PrivateRoute>
							<MyAccount />
						</PrivateRoute>
					}
				/>
				<Route
					path='/success'
					element={
						<PrivateRoute>
							<Success />
						</PrivateRoute>
					}
				/>
				<Route
					path='/update-success'
					element={
						<PrivateRoute>
							<UpdateSuccess />
						</PrivateRoute>
					}
				/>
				<Route
					path='/profile'
					element={
						<PrivateRoute>
							<UserProfile />
						</PrivateRoute>
					}
				/>
				<Route
					path='/dashboard'
					element={
						<PrivateRoute>
							<Dashboard />
						</PrivateRoute>
					}
				/>
				<Route
					path='/tools'
					element={
						<PrivateRoute>
							<Tools />
						</PrivateRoute>
					}
				/>
				<Route path='/chart/:id/total-votes' element={<TotalVotes />} />
				<Route path='/chart/:id' element={<Chart />} />
				<Route path='/' element={<BaseUrlRedirect />} />
			</Routes>
			<Background />
		</SnackbarProvider>
	);
}

export default App;
