import { useClipboard } from 'use-clipboard-copy';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
const deployedURL = process.env.REACT_APP_BASE_URL;

const PollPageSnippet = (props) => {
	const { yesBtnText, noBtnText, yesBtnLink, noBtnLink, id } = props;
	const clipboard = useClipboard({ copiedTimeout: 10 * 1000 });

	const codeSnippet = `<script>const pageLinks=document.getElementsByTagName("a"),url="${deployedURL}/api/polls/external/${id}",xhr=new XMLHttpRequest,handleClick=e=>{if(e.preventDefault(),"${yesBtnText}"===e.target.innerText){xhr.open("PUT",url),xhr.onload=(()=>window.location.assign("${yesBtnLink}")),xhr.setRequestHeader("Content-Type","application/json");var t='{"newYesVote": true,\t"newNoVote": false}';xhr.send(t)}else if("${noBtnText}"===e.target.innerText){xhr.open("PUT",url),xhr.onload=(()=>window.location.assign("${noBtnLink}")),xhr.setRequestHeader("Content-Type","application/json");t='{"newYesVote": false,\t"newNoVote": true}';xhr.send(t)}else window.location.assign(e.target.href)};for(let e=0;e<pageLinks.length;e++)pageLinks[e].addEventListener("click",handleClick);</script>`;

	return (
		<Paper className='snippets' sx={{ maxWidth: 275, height: 300 }}>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					maxWidth: 275,
					height: 300,
					alignItems: 'center',
					alignContent: 'center',
				}}
			>
				<CardContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>
					<div>
						<Typography
							sx={{ fontSize: 20 }}
							color='text.secondary'
							gutterBottom
						>
							Poll Page Snippet
						</Typography>
					</div>
					<div>
						<Typography variant='body2'>
							This snippet goes in the footer section of your landing page.
						</Typography>
						<br />
						<Typography variant='body2'>
							Press the COPY POLL PAGE SNIPPET button below and paste the code
							into the landing page footer or just above the closing body tag.
						</Typography>
					</div>
					<div>
						<CardActions>
							<Button
								fullWidth
								size='small'
								variant='contained'
								onClick={() => clipboard.copy(codeSnippet)}
							>
								{clipboard.copied ? 'Snippet Copied' : 'COPY POLL PAGE SNIPPET'}
							</Button>
						</CardActions>
					</div>
				</CardContent>
			</Card>
		</Paper>
	);
};

export default PollPageSnippet;
