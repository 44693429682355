import axios from 'axios';
import { frontendEnv } from './frontendEnv';

export const axiosWithAuth = () => {
	const user = JSON.parse(localStorage.getItem('userInfo'));
	return axios.create({
		headers: {
			Authorization: `Bearer ${user.token.toString()}`,
		},
		baseURL: frontendEnv(),
	});
};
