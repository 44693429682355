import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip,
} from 'chart.js';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';

const Chart = () => {
	const [chartName, setChartName] = useState('');
	const [error, setError] = useState();
	const [loading, setLoading] = useState(true);
	const [noVotes, setNoVotes] = useState(0);
	const [showChartLink, setShowChartLink] = useState(true);
	const [totalVotes, setTotalVotes] = useState(0);
	const [yesVotes, setYesVotes] = useState(0);

	const [chartLink, setChartLink] = useState();
	const [chartLinkText, setChartLinkText] = useState();

	const location = useLocation();

	const pathArray = location.pathname.split('/');
	const id = pathArray[pathArray.length - 1];

	useEffect(() => {
		axios
			.get(`/api/polls/poll/${id}`)
			.then((res) => {
				setChartLink(`${res.data.chartLink}`);
				setChartLinkText(`${res.data.chartLinkText}`);
				setChartName(`${res.data.pollName}`);
				setNoVotes(res.data.noVotes);
				setShowChartLink(res.data.showChartLink);
				setTotalVotes(res.data.totalVotes);
				setYesVotes(res.data.yesVotes);
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
			});
		// eslint-disable-next-line
	}, []);

	ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

	const options = {
		responsive: true,
		plugins: {
			title: {
				display: false,
				text: chartName,
			},
		},
	};

	const labels = ['Yes Votes', 'No Votes', 'Total Votes'];

	const data = {
		labels,
		datasets: [
			{
				data: [yesVotes, noVotes, totalVotes],
				backgroundColor: [
					'rgba(75, 192, 192, 0.5)',
					'rgba(255, 99, 132, 0.5)',
					'rgba(53, 162, 235, 0.5)',
				],
				borderColor: [
					'rgb(75, 192, 192)',
					'rgb(255, 99, 132)',
					'rgb(54, 162, 235)',
				],
				borderWidth: 1,
			},
		],
	};

	return (
		<div>
			{loading ? (
				<CircularProgress />
			) : error ? (
				<Alert variant='error'>{error}</Alert>
			) : (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Bar options={options} data={data} width={100} height={25} />
					{showChartLink ? (
						<div
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<a
								style={{ textDecoration: 'none', marginLeft: '40px' }}
								href={chartLink}
								target={'_blank'}
								rel={'noreferrer'}
							>
								<Typography color={'lightgray'} fontSize={'13px'}>
									{chartLinkText}
								</Typography>
							</a>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default Chart;
