import { useClipboard } from 'use-clipboard-copy';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const LoadingPageChart = (props) => {
	const deployedURL = process.env.REACT_APP_BASE_URL;
	const clipboard = useClipboard({ copiedTimeout: 10 * 1000 });

	const codeSnippet = `<iframe src="${deployedURL}/chart/${props.id}" style="border:none;width:100%;height:200px;display:auto;"></iframe>`;

	return (
		<Paper className='snippets'>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					maxWidth: 275,
					height: 300,
					alignItems: 'center',
					alignContent: 'center',
				}}
			>
				<CardContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>
					<div>
						<Typography
							sx={{ fontSize: 20 }}
							color='text.secondary'
							gutterBottom
						>
							Loading Page Chart
						</Typography>
					</div>
					<div>
						<Typography variant='body2'>
							This snippet goes anywhere you want the poll chart to show up.
						</Typography>
						<br />
						<Typography variant='body2'>
							Press the COPY CHART SNIPPET button below and paste the code onto
							your chart page.
						</Typography>
					</div>
					<div>
						<CardActions>
							<Button
								fullWidth
								size='small'
								variant='contained'
								onClick={() => clipboard.copy(codeSnippet)}
							>
								{clipboard.copied ? 'Snippet Copied' : 'COPY CHART SNIPPET'}
							</Button>
						</CardActions>
					</div>
				</CardContent>
			</Card>
		</Paper>
	);
};

export default LoadingPageChart;
