import { axiosWithAuth } from './axiosWithAuth';

const deployedURL = process.env.REACT_APP_BASE_URL;

let currentBackend;

if (process.env.NODE_ENV === 'production') {
	currentBackend = `${deployedURL}`;
} else {
	currentBackend = 'http://localhost:5000';
}

export const handlePortal = (id) => {
	let data;

	if (currentBackend === 'http://localhost:5000') {
		data = { returnUrl: 'http://localhost:3000/logout' };
	} else {
		data = { returnUrl: 'https://app.pollmagic.io/logout' };
	}

	axiosWithAuth()
		.post(`/api/users/create-portal/${id}`, data)
		.then((res) => {
			window.location.replace(res.data.url);
		})
		.catch((err) => console.error(err));
};
