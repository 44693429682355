import { useClipboard } from 'use-clipboard-copy';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
const salesPage = process.env.REACT_APP_SALES_PAGE;

const RedirectSnippet = ({ redirectUrl = salesPage, redirectDelay = 30 }) => {
	const clipboard = useClipboard({ copiedTimeout: 10 * 1000 });

	const redirectSnippet = `<script>setTimeout(function(){window.open("${redirectUrl}","_blank")},${redirectDelay}*1000);</script>`;

	return (
		<Paper className='snippets' sx={{ maxWidth: 275, height: 300 }}>
			<Card
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					maxWidth: 275,
					height: 300,
					alignItems: 'center',
					alignContent: 'center',
				}}
			>
				<CardContent
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
					}}
				>
					<div>
						<Typography
							sx={{ fontSize: 20 }}
							color='text.secondary'
							gutterBottom
						>
							Auto Forward Snippet
						</Typography>
					</div>
					<div>
						<Typography variant='body2'>
							This snippet is used to automatically open a new tab with your
							desired link after your desired delay.
						</Typography>
						<br />
						<Typography variant='body2'>
							Press the COPY AUTO FORWARD SNIPPET button below and paste the
							code just above the closing body tag of the page you wish to have
							auto-forward enabled.
						</Typography>
					</div>
					<div>
						<CardActions>
							<Button
								fullWidth
								size='small'
								variant='contained'
								onClick={() => clipboard.copy(redirectSnippet)}
							>
								{clipboard.copied ? 'Snippet Copied' : 'COPY REDIRECT SNIPPET'}
							</Button>
						</CardActions>
					</div>
				</CardContent>
			</Card>
		</Paper>
	);
};

export default RedirectSnippet;
