import { useLocation } from 'react-router-dom';

const Background = () => {
	const { pathname } = useLocation();
	const id = pathname.split('/')[2];

	if (pathname === `/chart/${id}/total-votes`) {
		return null;
	} else if (pathname === `/chart/${id}`) {
		return null;
	}
	return <div className='background-container'></div>;
};

export default Background;
