import {
	POLL_LIST_REQUEST,
	POLL_LIST_SUCCESS,
	POLL_LIST_FAIL,
	POLL_DETAILS_REQUEST,
	POLL_DETAILS_SUCCESS,
	POLL_DETAILS_FAIL,
} from '../constants/pollConstants';
import { axiosWithAuth } from '../utils/axiosWithAuth';

export const listPolls = () => async (dispatch) => {
	try {
		dispatch({ type: POLL_LIST_REQUEST });

		const { data } = await axiosWithAuth().get('/api/polls');

		dispatch({
			type: POLL_LIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: POLL_LIST_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const listUserPolls = (id) => async (dispatch) => {
	try {
		dispatch({ type: POLL_LIST_REQUEST });

		const { data } = await axiosWithAuth().get(`/api/polls/${id}`);

		dispatch({
			type: POLL_LIST_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: POLL_LIST_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};

export const listPollDetails = (id) => async (dispatch) => {
	try {
		dispatch({ type: POLL_DETAILS_REQUEST });

		const { data } = await axiosWithAuth().get(`/api/polls/${id}`);

		dispatch({
			type: POLL_DETAILS_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: POLL_DETAILS_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
