export const testPlans = [
	{
		// 0
		id: 'price_1KmpOwBHIP2yMrXo45CGoo8W',
		description: 'Basic',
		interval: 'Monthly',
	},
	{
		// 1
		id: 'price_1KmpPFBHIP2yMrXo4r1BChSa',
		description: 'Basic',
		interval: 'Yearly',
	},
	{
		// 2
		id: 'price_1KmphDBHIP2yMrXojB3Pxa7Z',
		description: 'Pro',
		interval: 'Monthly',
	},
	{
		// 3
		id: 'price_1KmpjqBHIP2yMrXoyTJPAYIU',
		description: 'Pro',
		interval: 'Yearly',
	},
	{
		// 4
		id: 'price_1KmptyBHIP2yMrXolXgJliy6',
		description: 'Ultimate',
		interval: 'Monthly',
	},
	{
		// 5
		id: 'price_1KmptyBHIP2yMrXoK4KUQjt4',
		description: 'Ultimate',
		interval: 'Yearly',
	},
	{
		// 6
		id: 'price_1KmpvIBHIP2yMrXosb9FctkZ',
		description: 'Agency',
		interval: 'Monthly',
	},
	{
		// 7
		id: 'price_1KmpvIBHIP2yMrXo1KQ61fyh',
		description: 'Agency',
		interval: 'Yearly',
	},
];

export const livePlans = [
	{
		id: 'price_1Koc2ZBHIP2yMrXowD2NZDUo',
		description: 'Basic',
		interval: 'Monthly',
	},
	{
		id: 'price_1Koc2ZBHIP2yMrXoqCWYkt6P',
		description: 'Basic',
		interval: 'Yearly',
	},
	{
		id: 'price_1Koc2hBHIP2yMrXod3v15VtA',
		description: 'Pro',
		interval: 'Monthly',
	},
	{
		id: 'price_1Koc2hBHIP2yMrXoyXO4EDEY',
		description: 'Pro',
		interval: 'Yearly',
	},
	{
		id: 'price_1Koc2rBHIP2yMrXo5fsTWSGM',
		description: 'Ultimate',
		interval: 'Monthly',
	},
	{
		id: 'price_1Koc2rBHIP2yMrXoHWe4EDzw',
		description: 'Ultimate',
		interval: 'Yearly',
	},
	{
		id: 'price_1Koc2wBHIP2yMrXoxaf6MkD9',
		description: 'Agency',
		interval: 'Monthly',
	},
	{
		id: 'price_1Koc2wBHIP2yMrXoJBdYecqL',
		description: 'Agency',
		interval: 'Yearly',
	},
];
