import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const YesButton = styled(Button)({
	textTransform: 'none',
	minWidth: 150,
	height: 31,
	padding: '4px 10px',
	backgroundColor: '#2e7d32',
	color: 'white',
	fontSize: 14,
	'&:hover': {
		backgroundColor: '#1b5e20',
		boxShadow: 'none',
	},
});

export default YesButton;
