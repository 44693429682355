import { Link } from 'react-router-dom';
import { frontendEnv } from '../../utils/frontendEnv';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Header from '../header/Navbar';
import Logo from '../../images/logo.png';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const ForgotPassword = () => {
	const [forgotPasswordForm, setForgotPasswordForm] = useState({
		email: '',
	});

	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const [disableButton, setDisableButton] = useState(false);

	const [instructions, setInstructions] = useState(1);

	const { enqueueSnackbar } = useSnackbar();

	const handleChange = (e, type) => {
		setForgotPasswordForm({ ...forgotPasswordForm, [type]: e.target.value });
	};

	const submitHandler = (e) => {
		e.preventDefault();
		setDisableButton(true);
		setLoading(true);

		axios
			.post(`${frontendEnv()}/api/users/password-reset`, {
				email: forgotPasswordForm.email,
			})
			.then((res) => {
				setInstructions(2);
				enqueueSnackbar(res.data.message, 'error');
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				setDisableButton(false);
				setError(err.message);
			});
	};

	useEffect(() => {
		document.title = `Poll Magic | Forgot Password`;
	});

	return (
		<>
			<Header />
			<div className='user-form'>
				<Grid className='forgot-password-form'>
					<Paper elevation={10} className='form-paper'>
						<Grid align='center'>
							<CardContent>
								<div>
									<a href={frontendEnv()}>
										<img src={Logo} alt='PollMagic.io Logo' />
									</a>
								</div>
								<Typography variant='h3'>Forgot Password</Typography>
								{instructions === 1 ? (
									<Typography className='subheading'>
										Enter your email address to continue
									</Typography>
								) : (
									<Typography
										className='subheading'
										style={{ color: 'red', fontWeight: 'bold' }}
									>
										Check your email to reset password
									</Typography>
								)}

								{error && <Alert severity='error'>{error}</Alert>}
								{loading && <CircularProgress />}
								<form onSubmit={submitHandler}>
									<TextField
										inputProps={{
											autoComplete: 'username',
										}}
										size='small'
										fullWidth
										margin='dense'
										required
										type='email'
										label='Email'
										name='email'
										onChange={(e) => handleChange(e, 'email')}
										value={forgotPasswordForm.email}
									/>

									<Button
										type='submit'
										variant='contained'
										disabled={disableButton}
										fullWidth
									>
										Continue
									</Button>
								</form>
								<Grid>
									<div className='cancel'>
										<Link to='/login'>
											<Typography fontSize={12}>CANCEL</Typography>
										</Link>
									</div>
								</Grid>
							</CardContent>
						</Grid>
					</Paper>
				</Grid>
			</div>
		</>
	);
};

export default ForgotPassword;
