import { Link, Typography } from '@mui/material';
import { useState } from 'react';
import Box from '@mui/material/Box';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
// import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

const EnhancedTable = ({ users, stableSort, getComparator }) => {
	// const [dense, setDense] = useState(false);
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('name');
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [selected, setSelected] = useState([]);

	const handleRequestSort = (e, property) => {
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);

		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	// const handleChangeDense = (e) => {
	// 	setDense(e.target.checked);
	// };

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				<EnhancedTableToolbar numSelected={selected.length} />
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size='small'
						// size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={users.length}
						/>
						<TableBody>
							{stableSort(users, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((user) => {
									return (
										<TableRow
											hover
											key={user._id}
											onClick={(e) => handleClick(e, user.stripeId)}
										>
											<TableCell padding='none' align='center'>
												<Typography>
													{`${user.lastName}, ${user.firstName}`}
												</Typography>
											</TableCell>
											<TableCell align='center' padding='none'>
												<Typography>{user.email}</Typography>
											</TableCell>
											<TableCell align='center' padding='none'>
												<Typography>
													<Link>{user.stripeId}</Link>
												</Typography>
											</TableCell>
											<TableCell align='center' padding='none'>
												<Typography>
													{`${user.subscription}/${user.subscriptionInterval}`}
												</Typography>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 33 * emptyRows,
										// height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component='div'
					count={users.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			{/* <FormControlLabel
				control={<Switch checked={dense} onChange={handleChangeDense} />}
				label='Dense padding'
				sx={{ color: '#fff' }}
			/> */}
		</Box>
	);
};

export default EnhancedTable;
