import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const DeleteButton = styled(Button)({
	padding: '4px 10px',
	backgroundColor: '#C62828',
	color: 'white',
	fontSize: 14,
	'&:hover': {
		backgroundColor: '#ad2424',
		boxShadow: 'none',
	},
});

export default DeleteButton;
