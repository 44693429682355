import { axiosWithAuth } from '../../utils/axiosWithAuth';
import { listUserPolls } from '../../actions/pollActions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

const PollTableEditor = ({ modalOpen, pollData, setModalOpen }) => {
	const dispatch = useDispatch();

	const [error, setError] = useState();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const handleCancel = () => {
		setModalOpen(false);
	};

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		setValue('yesVotes', pollData.yesVotes);
		setValue('noVotes', pollData.noVotes);
	}, [setValue, pollData.yesVotes, pollData.noVotes]);

	const onSubmit = (data) => {
		const updatedPoll = {
			...pollData,
			...data,
			totalVotes: parseInt(data.yesVotes) + parseInt(data.noVotes),
		};

		axiosWithAuth()
			.put(`/api/polls/${pollData.id}`, updatedPoll)
			.then(() => {
				dispatch(listUserPolls(userInfo._id));
				setModalOpen(false);
			})
			.catch((err) => {
				setError(err);
				console.error(err);
			});
	};

	return (
		<>
			<Dialog
				open={modalOpen}
				onClose={handleCancel}
				className='edit-poll-form-container'
			>
				<DialogTitle>Edit {pollData.pollName}</DialogTitle>
				<Typography variant='body2' className='notice'>
					** Updates here will require you to copy the updated snippets to your
					website.
				</Typography>
				{error && <Alert severity='error'>{error}</Alert>}
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)} className='edit-poll-form'>
						<TextField
							label='Poll name'
							fullWidth
							margin='dense'
							size='small'
							placeholder='Poll name'
							name='pollName'
							defaultValue={pollData.pollName}
							{...register('pollName', {
								required: 'Poll name is required',
							})}
							error={!!errors?.pollName}
							helperText={errors.pollName ? errors.pollName.message : null}
						/>
						<TextField
							label='"Yes" button text'
							fullWidth
							margin='dense'
							size='small'
							placeholder='"Yes" button text'
							name='yesBtnText'
							defaultValue={pollData.yesBtnText}
							{...register('yesBtnText', {
								required: '"Yes" button text is required',
							})}
							error={!!errors?.yesBtnText}
							helperText={errors.yesBtnText ? errors.yesBtnText.message : null}
						/>

						<Grid
							id='yes-btn-protocol'
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<Select
								labelId='yes-btn-protocol-select-label'
								id='yes-btn-protocol-select'
								label='Yes Button Protocol'
								sx={{
									padding: '0',
									marginRight: '5px',
									marginTop: '4px',
									height: '40px',
									width: '99.1px',
								}}
								defaultValue={pollData.yesBtnProtocol}
								{...register('yesBtnProtocol')}
							>
								<MenuItem value={'http://'} size='small'>
									<Typography>http://</Typography>
								</MenuItem>
								<MenuItem value={'https://'} size='small'>
									<Typography>https://</Typography>
								</MenuItem>
							</Select>

							<TextField
								label='Yes button link'
								fullWidth
								margin='dense'
								size='small'
								placeholder='Yes button link'
								name='yesBtnLink'
								defaultValue={pollData.yesBtnLink}
								sx={{ width: '250px' }}
								{...register('yesBtnLink', {
									pattern: {
										value:
											/^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/g,
										message: 'Invalid URL',
									},
								})}
								error={!!errors?.yesBtnLink}
								helperText={
									errors.yesBtnLink ? errors.yesBtnLink.message : null
								}
							/>
							<Typography
								sx={{ fontSize: '30px', margin: '0 2px', color: 'gray' }}
							>
								/
							</Typography>
							<TextField
								label='Yes button path (optional)'
								size='small'
								margin='dense'
								sx={{ width: '250px' }}
								defaultValue={pollData.yesBtnPath}
								{...register('yesBtnPath')}
							/>
						</Grid>

						<TextField
							label='"No" button text'
							fullWidth
							margin='dense'
							size='small'
							placeholder='"No" button text'
							name='noBtnText'
							defaultValue={pollData.noBtnText}
							{...register('noBtnText')}
							error={!!errors?.noBtnText}
							helperText={errors.noBtnText ? errors.noBtnText.message : null}
						/>

						<Grid
							id='no-btn-protocol'
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<Select
								labelId='no-btn-protocol-select-label'
								id='no-btn-protocol-select'
								label='No Button Protocol'
								sx={{
									padding: '0',
									marginRight: '5px',
									marginTop: '4px',
									height: '40px',
									width: '99.1px',
								}}
								defaultValue={pollData.noBtnProtocol}
								{...register('noBtnProtocol')}
							>
								<MenuItem value={'http://'} size='small'>
									<Typography>http://</Typography>
								</MenuItem>
								<MenuItem value={'https://'} size='small'>
									<Typography>https://</Typography>
								</MenuItem>
							</Select>

							<TextField
								label='No button page link'
								fullWidth
								margin='dense'
								size='small'
								placeholder='No button page link'
								name='noBtnLink'
								defaultValue={pollData.noBtnLink}
								sx={{ width: '250px' }}
								{...register('noBtnLink', {
									pattern: {
										value:
											/^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/g,
										message: 'Invalid URL',
									},
								})}
								error={!!errors?.noBtnLink}
								helperText={errors.noBtnLink ? errors.noBtnLink.message : null}
							/>
							<Typography
								sx={{ fontSize: '30px', margin: '0 2px', color: 'gray' }}
							>
								/
							</Typography>
							<TextField
								label='No button path (optional)'
								size='small'
								margin='dense'
								sx={{ width: '250px' }}
								defaultValue={pollData.noBtnPath}
								{...register('noBtnPath')}
							/>
						</Grid>

						{userInfo.subscription === 'Free' ? (
							<div className='disabled-vote-altering'>
								<Typography variant='h6' align='center'>
									Upgraded plan required to alter votes count
								</Typography>
								<Link to='/my-account'>
									<Button variant='contained' size='small'>
										Upgrade
									</Button>
								</Link>
							</div>
						) : (
							<>
								<TextField
									label='Yes votes'
									fullWidth
									margin='dense'
									size='small'
									placeholder='"No" Button Text'
									name='yesVotes'
									defaultValue={pollData.yesVotes}
									{...register('yesVotes', {
										required: 'Yes votes number required',
									})}
									error={!!errors?.yesVotes}
									helperText={errors.yesVotes ? errors.yesVotes.message : null}
								/>
								<TextField
									label='No votes'
									fullWidth
									margin='dense'
									size='small'
									placeholder='"No" Button Text'
									name='noVotes'
									defaultValue={pollData.noVotes}
									{...register('noVotes', {
										required: 'No votes number required',
									})}
									error={!!errors?.noVotes}
									helperText={errors.noVotes ? errors.noVotes.message : null}
								/>
							</>
						)}

						<Grid
							id='poll-page-link'
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<Select
								labelId='protocol-select-label'
								id='protocol-select'
								label='Protocol'
								sx={{
									padding: '0',
									marginRight: '5px',
									marginTop: '4px',
									height: '40px',
									width: '99.1px',
								}}
								defaultValue={pollData.protocol}
								{...register('protocol')}
							>
								<MenuItem value={'http://'} size='small'>
									<Typography>http://</Typography>
								</MenuItem>
								<MenuItem value={'https://'} size='small'>
									<Typography>https://</Typography>
								</MenuItem>
							</Select>
							<TextField
								label='Poll page link'
								fullWidth
								margin='dense'
								size='small'
								placeholder='Poll page link'
								name='pollPageLink'
								defaultValue={pollData.pollPageLink}
								sx={{ width: '250px' }}
								{...register('pollPageLink', {
									required: 'Error page URL is required (can be edited later)',
									pattern: {
										value:
											/^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/g,
										message: 'Invalid URL',
									},
								})}
								error={!!errors?.pollPageLink}
								helperText={
									errors.pollPageLink ? errors.pollPageLink.message : null
								}
							/>
							<Typography
								sx={{ fontSize: '30px', margin: '0 2px', color: 'gray' }}
							>
								/
							</Typography>
							<TextField
								label='Path (optional)'
								size='small'
								margin='dense'
								sx={{ width: '250px' }}
								defaultValue={pollData.pollPagePath}
								{...register('pollPagePath')}
							/>
						</Grid>

						<Grid
							id='poll-page-error-link'
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignContent: 'center',
								alignItems: 'center',
							}}
						>
							<Select
								labelId='protocol-select-label'
								id='protocol-select'
								label='Protocol'
								sx={{
									padding: '0',
									marginRight: '5px',
									marginTop: '4px',
									height: '40px',
									width: '99.1px',
								}}
								defaultValue={pollData.errorProtocol}
								{...register('errorProtocol')}
							>
								<MenuItem value={'http://'} size='small'>
									<Typography>http://</Typography>
								</MenuItem>
								<MenuItem value={'https://'} size='small'>
									<Typography>https://</Typography>
								</MenuItem>
							</Select>
							<TextField
								label='Poll page error link'
								fullWidth
								margin='dense'
								size='small'
								placeholder='Poll page error link'
								name='errorPageLink'
								defaultValue={pollData.errorPageLink}
								sx={{ width: '250px' }}
								{...register('errorPageLink', {
									required: 'Error page URL is required (can be edited later)',
									pattern: {
										value:
											/^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/g,
										message: 'Invalid URL',
									},
								})}
								error={!!errors?.errorPageLink}
								helperText={
									errors.errorPageLink ? errors.errorPageLink.message : null
								}
							/>
							<Typography
								sx={{ fontSize: '30px', margin: '0 2px', color: 'gray' }}
							>
								/
							</Typography>
							<TextField
								label='Path (optional)'
								size='small'
								margin='dense'
								sx={{ width: '250px' }}
								defaultValue={pollData.errorPagePath}
								{...register('errorPagePath')}
							/>
						</Grid>

						<Grid align='center' id='auto-forward-link'>
							{userInfo.subscription === 'Free' ||
							userInfo.subscription === 'Basic' ||
							userInfo.subscription === 'Bonus' ? (
								<div className='disabled-auto-forward'>
									<Typography variant='h6' align='center'>
										Upgraded plan required to enable auto-forwarding
									</Typography>
									<Link to='/my-account'>
										<Button variant='contained' size='small'>
											Upgrade
										</Button>
									</Link>
								</div>
							) : (
								<>
									<Grid
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignContent: 'center',
											alignItems: 'center',
										}}
									>
										<Select
											labelId='auto-forward-label'
											id='auto-forward'
											label='Auto-Forward Link'
											sx={{
												padding: '0',
												marginRight: '5px',
												marginTop: '4px',
												height: '40px',
												width: '99.1px',
											}}
											defaultValue={pollData.autoForwardProtocol}
											{...register('autoForwardProtocol')}
										>
											<MenuItem value={'http://'} size='small'>
												<Typography>http://</Typography>
											</MenuItem>
											<MenuItem value={'https://'} size='small'>
												<Typography>https://</Typography>
											</MenuItem>
										</Select>
										<TextField
											label='Auto-forward link'
											fullWidth
											margin='dense'
											size='small'
											placeholder='Auto-forward link'
											name='autoForwardLink'
											sx={{ width: '250px' }}
											defaultValue={pollData.autoForwardLink}
											{...register('autoForwardLink', {
												pattern: {
													value:
														/^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$/g,
													message: 'Invalid URL',
												},
											})}
											error={!!errors?.autoForwardLink}
											helperText={
												errors.autoForwardLink
													? errors.autoForwardLink.message
													: null
											}
										/>
										<Typography
											sx={{ fontSize: '30px', margin: '0 2px', color: 'gray' }}
										>
											/
										</Typography>
										<TextField
											label='Path (optional)'
											size='small'
											margin='dense'
											sx={{ width: '250px' }}
											defaultValue={pollData.autoForwardPath}
											{...register('autoForwardPath')}
										/>
									</Grid>
									<Grid
										align='center'
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<TextField
											label='Auto-forward Delay'
											fullWidth
											margin='dense'
											size='small'
											placeholder='Auto-forward Delay'
											name='redirectDelay'
											sx={{ width: '140px' }}
											required
											defaultValue={pollData.redirectDelay}
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														seconds
													</InputAdornment>
												),
											}}
											{...register('redirectDelay', {
												minLength: 1,
												pattern: {
													required:
														'Auto-forward delay (in seconds) is required',
													value: /^[0-9]*$/g,
													message: 'Must be a number',
												},
											})}
											error={!!errors?.redirectDelay}
											helperText={
												errors.redirectDelay
													? errors.redirectDelay.message
													: null
											}
										/>
									</Grid>
								</>
							)}
						</Grid>

						<Button
							variant='contained'
							type='submit'
							sx={{ marginTop: '10px' }}
							fullWidth
						>
							Submit
						</Button>
					</form>
					<Grid align='right' style={{ marginTop: 20 }}>
						<Button
							onClick={handleCancel}
							variant='text'
							size='small'
							margin='dense'
						>
							Cancel
						</Button>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PollTableEditor;
