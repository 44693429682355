import { Elements } from '@stripe/react-stripe-js';
import { axiosWithAuth } from '../utils/axiosWithAuth';
import { loadStripe } from '@stripe/stripe-js';
import { useState, useEffect } from 'react';
import CheckoutForm from '../components/stripe/CheckoutForm';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// publishable API key.
let stripeKey;

if (process.env.NODE_ENV === 'production') {
	stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
} else {
	stripeKey = process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
}

const stripePromise = loadStripe(stripeKey);

const CheckoutPage = () => {
	const [clientSecret, setClientSecret] = useState('');

	const search = window.location.search.split('=');
	const price = search[1].split('&')[0];
	const stripeId = search[2].split('&')[0];
	const customerId = search[3];

	useEffect(() => {
		const transaction = {
			priceId: price,
			stripeId: stripeId,
			customerId: customerId,
		};

		axiosWithAuth()
			.post('/api/checkout', transaction)
			.then((res) => {
				setClientSecret(res.data.clientSecret);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [customerId, price, stripeId]);

	const appearance = {
		theme: 'stripe',
		labels: 'floating',
	};

	const options = {
		clientSecret,
		appearance,
	};

	return (
		<div className='checkout-page'>
			{clientSecret && (
				<Elements options={options} stripe={stripePromise}>
					<CheckoutForm />
				</Elements>
			)}
		</div>
	);
};

export default CheckoutPage;
