export const frontendEnv = () => {
	const hostName = window.location.hostname;

	let currentURL;

	if (process.env.NODE_ENV === 'production') {
		if (hostName === 'pollmagic-deploy-staging.herokuapp.com') {
			currentURL = 'https://pollmagic-deploy-staging.herokuapp.com';
		} else if (hostName === 'app.pollmagic.io') {
			currentURL = 'https://app.pollmagic.io';
		}
	} else {
		currentURL = 'http://localhost:3000';
	}

	return currentURL;
};
