import { useClipboard } from 'use-clipboard-copy';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import EditIcon from '@mui/icons-material/Edit';
import Paper from '@mui/material/Paper';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

const SocialProofSnippet = (props) => {
	const { id, setEditSocialProofDialogOpen, setSocialTextModalOpen } = props;

	const deployedURL = process.env.REACT_APP_BASE_URL;
	const clipboard = useClipboard({ copiedTimeout: 10 * 1000 });

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = !!anchorEl;

	const handleClick = () => {
		if (userInfo.subscription === 'Free') {
			setEditSocialProofDialogOpen(true);
		} else {
			setSocialTextModalOpen(true);
		}
	};

	const codeSnippet = `<iframe src="${deployedURL}/chart/${id}/total-votes" scrolling="no" style="border:none;overflow:hidden;width:100%;height:70px;"></iframe>`;

	return (
		<Paper className='snippets'>
			<Card
				className='social-proof-card-container'
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					maxWidth: 275,
					height: 300,
					alignItems: 'center',
					alignContent: 'center',
				}}
			>
				<CardContent className='social-proof-card'>
					<div
						className='edit-social-proof'
						style={{
							position: 'absolute',
							right: 10,
						}}
						aria-owns={open ? 'mouse-over-popover' : undefined}
						aria-haspopup='true'
						onMouseEnter={handlePopoverOpen}
						onMouseLeave={handlePopoverClose}
					>
						<EditIcon onClick={handleClick} />
					</div>
					<Popover
						id='mouse-over-popover'
						sx={{ pointerEvents: 'none' }}
						open={open}
						anchorEl={anchorEl}
						anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
						transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
						onClose={handlePopoverClose}
						disableRestoreFocus
					>
						<Typography
							style={{
								backgroundColor: 'black',
								color: 'white',
								padding: '10px',
								fontWeight: 600,
							}}
						>
							Edit social proof text
						</Typography>
					</Popover>
					<div>
						<Typography
							sx={{ fontSize: 20 }}
							color='text.secondary'
							gutterBottom
						>
							Poll Social Proof
						</Typography>
					</div>
					<div>
						<Typography variant='body2'>
							This snippet goes anywhere you want to display the number of
							people who have taken the survey.
						</Typography>
						<br />
						<Typography variant='body2'>
							Press the COPY SOCIAL PROOF SNIPPET button below and paste the
							code into your page.
						</Typography>
					</div>
					<div>
						<CardActions>
							<Button
								fullWidth
								size='small'
								variant='contained'
								onClick={() => clipboard.copy(codeSnippet)}
							>
								{clipboard.copied
									? 'Snippet Copied'
									: 'COPY SOCIAL PROOF SNIPPET'}
							</Button>
						</CardActions>
					</div>
				</CardContent>
			</Card>
		</Paper>
	);
};

export default SocialProofSnippet;
