import {
	POLL_LIST_REQUEST,
	POLL_LIST_SUCCESS,
	POLL_LIST_FAIL,
	POLL_DETAILS_REQUEST,
	POLL_DETAILS_SUCCESS,
	POLL_DETAILS_FAIL,
} from '../constants/pollConstants';

export const pollListReducer = (state = { polls: [] }, action) => {
	switch (action.type) {
		case POLL_LIST_REQUEST:
			return { loading: true, polls: [] };
		case POLL_LIST_SUCCESS:
			return { loading: false, polls: action.payload };
		case POLL_LIST_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};

export const pollDetailsReducer = (state = { poll: {} }, action) => {
	switch (action.type) {
		case POLL_DETAILS_REQUEST:
			return { loading: true, ...state };
		case POLL_DETAILS_SUCCESS:
			return { loading: false, poll: action.payload };
		case POLL_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		default:
			return state;
	}
};
