import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import Typography from '@mui/material/Typography';

const deployedURL = process.env.REACT_APP_BASE_URL;

let successPage;

if (process.env.NODE_ENV === 'production') {
	successPage = `${deployedURL}/success`;
} else {
	successPage = 'http://localhost:3000/success';
}

const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('Payment succeeded!');
					break;
				case 'processing':
					setMessage('Your payment is processing.');
					break;
				case 'requires_payment_method':
					setMessage('Your payment was not successful, please try again.');
					break;
				default:
					setMessage('Something went wrong.');
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const payment = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: successPage,
			},
		});

		console.log(payment);

		/*
		This point will only be reached if there is an immediate error when
		confirming the payment. Otherwise, your customer will be redirected to
		your `return_url`. For some payment methods like iDEAL, your customer will
		be redirected to an intermediate site first to authorize the payment, then
		redirected to the `return_url`.
		*/
		if (
			payment.error.type === 'card_error' ||
			payment.error.type === 'validation_error'
		) {
			setMessage(payment.error.message);
		} else {
			setMessage('An unexpected error occured.');
		}

		setIsLoading(false);
	};

	return (
		<form id='payment-form' onSubmit={handleSubmit}>
			<PaymentElement id='payment-element' />
			<Grid align='center'>
				<LoadingButton
					loading={isLoading}
					// loadingPosition='start'
					variant='contained'
					disabled={isLoading || !stripe || !elements}
					id='submit'
					type='submit'
				>
					ADD PURCHASE DETAILS
					{isLoading ? (
						<Typography className='payment-submittal'>Processing</Typography>
					) : (
						<>
							Add checkbox to accept t&c
							<Typography>Pay now</Typography>
						</>
					)}
				</LoadingButton>
			</Grid>
			{message && <div id='payment-message'>{message}</div>}
		</form>
	);
};

export default CheckoutForm;
