import { axiosWithAuth } from '../utils/axiosWithAuth';
import { logout } from '../actions/userActions';
import { useDispatch /*useSelector*/ } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { convertDate } from '../utils/convertDate';

const UpdateSuccess = () => {
	// const userLogin = useSelector((state) => state.userLogin);
	// const { userInfo } = userLogin;

	// const [disabled, setDisabled] = useState(true);
	// const [invoice, setInvoice] = useState();
	const [subscription, setSubscription] = useState({});

	// const { enqueueSnackbar } = useSnackbar();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// const planType = userInfo.subscription;

	const logUserOut = () => {
		dispatch(logout);
		navigate('/login');
		window.location.reload();
	};

	useEffect(() => {
		const subscription = new URLSearchParams(window.location.search).get(
			'subscription'
		);

		axiosWithAuth()
			.get(`/api/users/retrieve-subscription/${subscription}`)
			.then((res) => {
				console.log(res.data);
				setSubscription(res.data);
			})
			.catch((err) => console.error(err));
	}, []);

	return (
		<div className='success-page'>
			<Typography variant='h2'>Subscription Update Success</Typography>
			<Typography variant='h5'>
				Your new plan begins {convertDate(subscription.current_period_start)}
			</Typography>
			<Button variant='contained' /*disabled={disabled}*/ onClick={logUserOut}>
				Apply New Features
			</Button>
		</div>
	);
};

export default UpdateSuccess;
