import { tools } from '../data/toolsList';
import { useEffect } from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Header from '../components/header/Navbar';
import Typography from '@mui/material/Typography';

const appName = process.env.REACT_APP_PAGE_TITLE;

const Tools = () => {
	useEffect(() => {
		document.title = `${appName} | Tools`;
	});

	return (
		<>
			<Header />
			<div className='tools-container'>
				<div className='tools-header'>
					<Typography variant='h1' sx={{ color: '#fff', fontSize: '30px' }}>
						Recommended Tools
					</Typography>
				</div>
				<div className='tools'>
					{tools.map((tool) => {
						return (
							<Card key={tool.id} className='tool-card'>
								<div className='tool-card-image'>
									{tool.youTubeEmbed ? (
										tool.youTubeEmbed
									) : (
										<a
											href={tool.affiliateLink}
											target='_blank'
											rel='noreferrer'
										>
											<CardMedia
												component='img'
												alt={tool.title}
												height='auto'
												image={tool.cardImageLink}
											/>
										</a>
									)}
								</div>
								<CardActions className='card-actions'>
									<a
										href={tool.affiliateLink}
										target='_blank'
										rel='noreferrer'
										className='tool-link'
									>
										<Button
											variant='contained'
											size='medium'
											sx={{ width: 200, background: '#1976d2', color: 'white' }}
										>
											Learn More
										</Button>
									</a>
								</CardActions>
								<CardContent>
									<Typography
										className='title'
										gutterBottom
										variant='h5'
										component='div'
									>
										{tool.title}
									</Typography>
									<Typography
										className='subtitle'
										gutterBottom
										variant='h7'
										component='div'
									>
										{tool.subTitle}
									</Typography>
									<br />
									<Typography variant='body2'>{tool.description1}</Typography>
									<br />
									<Typography variant='body2'>{tool.description2}</Typography>
								</CardContent>
								{/* <CardActionArea >
									{tool.tags.map((tag) => {
										return <Typography variant='body2'>{tag}</Typography>;
									})}
								</CardActionArea> */}
							</Card>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default Tools;
