import { handlePortal } from '../../utils/handlePortal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AntSwitch from './BillingAntSwitch';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const PlanOptions = ({ plan, subscriptionInterval }) => {
	const [checked, setChecked] = useState(false);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	console.log(userInfo);

	const handleSwitch = () => {
		setChecked(!checked);
	};

	useEffect(() => {
		if (subscriptionInterval === 'Yearly') {
			setChecked(true);
		}
	}, [subscriptionInterval]);

	console.log(subscriptionInterval);

	// set card styling for current plan
	useEffect(() => {
		const agencyPlan = document.getElementById('agency-title');
		const ultimatePlan = document.getElementById('ultimate-title');
		const proPlan = document.getElementById('pro-title');
		const basicPlan = document.getElementById('basic-title');
		const freePlan = document.getElementById('free-title');
		if (plan === 'Ultimate') {
			agencyPlan.classList.remove('active');
			ultimatePlan.classList.add('active');
			proPlan.classList.remove('active');
			basicPlan.classList.remove('active');
			freePlan.classList.remove('active');
		} else if (plan === 'Free') {
			agencyPlan.classList.remove('active');
			ultimatePlan.classList.remove('active');
			proPlan.classList.remove('active');
			basicPlan.classList.remove('active');
			freePlan.classList.add('active');
		} else if (plan === 'Basic' || plan === 'Bonus') {
			agencyPlan.classList.remove('active');
			ultimatePlan.classList.remove('active');
			proPlan.classList.remove('active');
			basicPlan.classList.add('active');
			freePlan.classList.remove('active');
		} else if (plan === 'Beta' || plan === 'Pro') {
			proPlan.classList.add('active');
			ultimatePlan.classList.remove('active');
			freePlan.classList.remove('active');
			basicPlan.classList.remove('active');
		} else if (plan === 'Admin' || plan === 'Agency') {
			agencyPlan.classList.add('active');
			proPlan.classList.remove('active');
			ultimatePlan.classList.remove('active');
			freePlan.classList.remove('active');
			basicPlan.classList.remove('active');
		}
	}, [plan]);

	return (
		<div className='manage-plan-section'>
			<div className='monthly-yearly-slider'>
				<Stack direction='row' spacing={1} alignItems='center'>
					<Typography>MONTHLY</Typography>
					<AntSwitch
						inputProps={{ 'aria-label': 'ant design' }}
						checked={checked}
						onChange={handleSwitch}
					/>
					<Typography>YEARLY</Typography>
				</Stack>
			</div>

			<div className='plans'>
				<Grid className='pricing-table'>
					<div className='top-spacer transparent'></div>
					<Paper>
						<Card className='pricing-card free-plan'>
							<CardHeader
								title='FREE'
								className='card-header free active'
								id='free-title'
							/>
							<CardContent className='pricing-content'>
								<Typography>FREE</Typography>
								<Typography>Forever</Typography>

								<div className='plan-action-button'>
									{plan === 'Free' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									)}
								</div>
								<Typography className='includes'>INCLUDES</Typography>
								<Typography>Poll Limit: 1</Typography>
								<Typography>Light Branding</Typography>
							</CardContent>
						</Card>
					</Paper>
				</Grid>

				<Grid className='pricing-table'>
					<div className='top-spacer transparent'></div>
					<Paper>
						<Card className='pricing-card basic-plan'>
							<CardHeader
								title='BASIC'
								className='card-header basic'
								id='basic-title'
							/>
							<CardContent className='pricing-content'>
								{checked ? (
									<>
										<Typography>$97 /yr</Typography>
										<Typography>Paid Yearly</Typography>
									</>
								) : (
									<>
										{plan === 'Bonus' ? (
											<>
												<Typography>Bonus Subscription</Typography>
												<Typography>
													Thank You, {userInfo.firstName}!
												</Typography>
											</>
										) : (
											<>
												<Typography>$10 /mo</Typography>
												<Typography>Paid Monthly</Typography>
											</>
										)}
									</>
								)}

								<div className='plan-action-button'>
									{checked &&
									subscriptionInterval === 'Yearly' &&
									plan === 'Basic' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : checked &&
									  (plan === 'Pro' ||
											plan === 'Ultimate' ||
											plan === 'Agency' ||
											plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : checked &&
									  (plan === 'Free' || plan === 'Basic' || plan === 'Beta') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  (plan === 'Pro' ||
											plan === 'Ultimate' ||
											plan === 'Agency' ||
											plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  plan === 'Basic' ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Lose Discount
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Monthly' &&
									  (plan === 'Basic' || plan === 'Bonus') ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Monthly' &&
									  (plan === 'Pro' ||
											plan === 'Ultimate' ||
											plan === 'Agency' ||
											plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									)}
								</div>
								<Typography className='includes'>INCLUDES</Typography>
								<Typography>Poll Limit: 1</Typography>
								<Typography>Adjust Vote Count</Typography>
								<Typography>Edit Social Proof Text</Typography>
								<Typography>Edit Poll Link</Typography>
							</CardContent>
						</Card>
					</Paper>
				</Grid>

				<Grid className='pricing-table'>
					<div className='top-spacer'>MOST POPULAR</div>
					<Paper>
						<Card className='pricing-card pro-plan'>
							<CardHeader
								title='PRO'
								className='card-header pro'
								id='pro-title'
							/>
							<CardContent className='pricing-content'>
								{checked ? (
									<>
										<Typography>$297 /yr</Typography>
										<Typography>Paid Yearly</Typography>
									</>
								) : (
									<>
										<Typography>$27 /mo</Typography>
										<Typography>Paid Monthly</Typography>
									</>
								)}

								<div className='plan-action-button'>
									{checked &&
									subscriptionInterval === 'Yearly' &&
									plan === 'Pro' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : checked &&
									  (plan === 'Ultimate' ||
											plan === 'Agency' ||
											plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : checked &&
									  (plan === 'Free' ||
											plan === 'Basic' ||
											plan === 'Pro' ||
											plan === 'Beta') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  (plan === 'Ultimate' ||
											plan === 'Agency' ||
											plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  plan === 'Pro' ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Lose Discount
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Monthly' &&
									  plan === 'Pro' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Monthly' &&
									  (plan === 'Ultimate' ||
											plan === 'Agency' ||
											plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									)}
								</div>
								<Typography className='includes'>INCLUDES</Typography>
								<Typography>Poll Limit: 5</Typography>
								<Typography>Adjust Vote Count</Typography>
								<Typography>Edit Social Proof Text</Typography>
								<Typography>Edit Poll Link</Typography>
								<Typography>Auto-Forward Enabled</Typography>
								{plan === 'Beta' ? (
									<Typography className='beta-line-item'>
										Temporary Beta Access Account
									</Typography>
								) : null}
							</CardContent>
						</Card>
					</Paper>
				</Grid>

				<Grid className='pricing-table'>
					<div className='top-spacer transparent'></div>
					<Paper>
						<Card className='pricing-card ultimate-plan'>
							<CardHeader
								title='ULTIMATE'
								className='card-header ultimate'
								id='ultimate-title'
							/>
							<CardContent className='pricing-content'>
								{checked ? (
									<>
										<Typography>$997 /yr</Typography>
										<Typography>Paid Yearly</Typography>
									</>
								) : (
									<>
										<Typography>$97 /mo</Typography>
										<Typography>Paid Monthly</Typography>
									</>
								)}

								<div className='plan-action-button'>
									{checked &&
									subscriptionInterval === 'Yearly' &&
									plan === 'Ultimate' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : checked && (plan === 'Agency' || plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : checked &&
									  (plan === 'Free' ||
											plan === 'Basic' ||
											plan === 'Pro' ||
											plan === 'Ultimate' ||
											plan === 'Beta') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  (plan === 'Agency' || plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  plan === 'Ultimate' ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Lose Discount
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Monthly' &&
									  plan === 'Ultimate' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Monthly' &&
									  (plan === 'Agency' || plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Downgrade
										</Button>
									) : (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									)}
								</div>

								<Typography className='includes'>INCLUDES</Typography>
								<Typography>Poll Limit: 20</Typography>
								<Typography>Adjust Vote Count</Typography>
								<Typography>Edit Social Proof Text</Typography>
								<Typography>Edit Poll Link</Typography>
								<Typography>Auto-Forward Enabled</Typography>
								<Typography>Webhooks Enabled</Typography>
							</CardContent>
						</Card>
					</Paper>
				</Grid>

				<Grid className='pricing-table'>
					<div className='top-spacer transparent'></div>
					<Paper>
						<Card className='pricing-card agency-plan'>
							<CardHeader
								title='AGENCY'
								className='card-header agency'
								id='agency-title'
							/>
							<CardContent className='pricing-content'>
								{checked ? (
									<>
										<Typography>$1,997 /yr</Typography>
										<Typography>Paid Yearly</Typography>
									</>
								) : (
									<>
										<Typography>$197 /mo</Typography>
										<Typography>Paid Monthly</Typography>
									</>
								)}

								<div className='plan-action-button'>
									{checked &&
									subscriptionInterval === 'Yearly' &&
									plan === 'Agency' ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : checked &&
									  (plan === 'Free' ||
											plan === 'Basic' ||
											plan === 'Pro' ||
											plan === 'Ultimate' ||
											plan === 'Beta') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									) : !checked &&
									  subscriptionInterval === 'Yearly' &&
									  (plan === 'Agency' || plan === 'Admin') ? (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Lose Discount
										</Button>
									) : (!checked &&
											subscriptionInterval === 'Monthly' &&
											(plan === 'Agency' || plan === 'Admin')) ||
									  (subscriptionInterval === 'Monthly' && plan === 'Admin') ? (
										<Button variant='contained' fullWidth disabled>
											Current Plan
										</Button>
									) : (
										<Button
											variant='contained'
											fullWidth
											onClick={() => handlePortal(userInfo.stripeId)}
										>
											Upgrade
										</Button>
									)}
								</div>
								<Typography className='includes'>INCLUDES</Typography>
								<Typography>Poll Limit: 50</Typography>
								<Typography>Adjust Vote Count</Typography>
								<Typography>Edit Social Proof Text</Typography>
								<Typography>Edit Poll Link</Typography>
								<Typography>Auto-Forward Enabled</Typography>
								<Typography>Webhooks Enabled</Typography>
							</CardContent>
						</Card>
					</Paper>
				</Grid>
			</div>
		</div>
	);
};

export default PlanOptions;
