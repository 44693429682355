import { convertCents } from '../../utils/convertCents';
import { convertDate } from '../../utils/convertDate';
import { handlePortal } from '../../utils/handlePortal';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Amex from '../../images/amex_thumb.png';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Discover from '../../images/discover_thumb.png';
import MasterCard from '../../images/mastercard_thumb.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Visa from '../../images/visa_thumb.png';

const Invoices = (props) => {
	const { customerInvoices, paymentMethod, renewalDay, subscriptionPrice } =
		props;
	const [cardType, setCardType] = useState();

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	let expMonth;
	let expYear;

	if (paymentMethod) {
		if (paymentMethod.card.exp_month.toString().length < 2) {
			expMonth = `0${paymentMethod.card.exp_month}`;
		} else {
			expMonth = paymentMethod.card.exp_month;
		}
		expYear = paymentMethod.card.exp_year.toString().split('20')[1];
	}

	useEffect(() => {
		if (paymentMethod) {
			if (paymentMethod.card.brand === 'visa') {
				setCardType(Visa);
			} else if (paymentMethod.card.brand === 'mastercard') {
				setCardType(MasterCard);
			} else if (paymentMethod.card.brand === 'discover') {
				setCardType(Discover);
			} else if (paymentMethod.card.brand === 'amex') {
				setCardType(Amex);
			}
		}
	}, [paymentMethod]);

	const paidStatus = (status) => {
		if (status === true) {
			return (
				<Typography variant='body2' className='invoice-paid'>
					Paid
				</Typography>
			);
		} else {
			return (
				<Typography variant='body2' className='invoice-not-paid'>
					Due
				</Typography>
			);
		}
	};

	return (
		<>
			<div className='payment-details'>
				{!paymentMethod || !customerInvoices ? (
					<Typography className='no-payment-method' variant='h4'>
						No Payments On File
					</Typography>
				) : (
					<Card className='payment-method'>
						<CardContent>
							<Typography>Payment Method</Typography>
							<div className='card-details'>
								<img
									src={cardType}
									alt={paymentMethod.card.brand}
									className='card-image'
								/>
								<Typography>
									XXXXXXXXXXX{paymentMethod.card.last4} {expMonth}/{expYear}
								</Typography>
							</div>
							<Typography className='next-due'>
								{`Next due: ${renewalDay} - $${convertCents(
									subscriptionPrice
								)}`}
							</Typography>
							<Button>
								<Typography
									className='cancel-subscription'
									variant='caption'
									onClick={() => handlePortal(userInfo.stripeId)}
								>
									(Cancel)
								</Typography>
							</Button>
						</CardContent>
						<CardActions>
							<Button
								variant='contained'
								fullWidth
								onClick={() => handlePortal(userInfo.stripeId)}
							>
								Update Payment Method
							</Button>
						</CardActions>
					</Card>
				)}
			</div>
			<div className='invoices'>
				<Card>
					<Table size='small'>
						<TableHead sx={{ background: '#1976d2' }}>
							<TableRow>
								<TableCell align='center'>
									<Typography sx={{ color: '#fff' }}>Date</Typography>
								</TableCell>
								<TableCell align='center'>
									<Typography sx={{ color: '#fff' }}>Invoice #</Typography>
								</TableCell>
								<TableCell align='center'>
									<Typography sx={{ color: '#fff' }}>Subscription</Typography>
								</TableCell>
								<TableCell align='center'>
									<Typography sx={{ color: '#fff' }}>Total</Typography>
								</TableCell>
								<TableCell align='center'>
									<Typography sx={{ color: '#fff' }}>Status</Typography>
								</TableCell>
								<TableCell align='center'></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{customerInvoices.map((invoice) => {
								return (
									<TableRow key={invoice.id}>
										<TableCell align='center'>
											<Typography>{convertDate(invoice.created)}</Typography>
										</TableCell>
										<TableCell align='center'>
											<Typography>{invoice.number}</Typography>
										</TableCell>
										<TableCell align='center'>
											<Typography>
												{invoice.lines.data[0].description}
											</Typography>
										</TableCell>
										<TableCell align='center'>
											<Typography>${convertCents(invoice.total)}</Typography>
										</TableCell>
										<TableCell align='center'>
											{invoice.lines.data[0].description
												.split('× ')[1]
												.split(' ')[2] === 'Free' ? (
												<Typography variant='body2' className='invoice-paid'>
													Free
												</Typography>
											) : (
												paidStatus(invoice.paid)
											)}
										</TableCell>
										<TableCell align='center'>
											<a
												href={invoice.hosted_invoice_url}
												target='_blank'
												rel='noreferrer'
											>
												<Button>View</Button>
											</a>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Card>
			</div>
		</>
	);
};

export default Invoices;
