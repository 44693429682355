import { axiosWithAuth } from '../../utils/axiosWithAuth';
import { listUserPolls } from '../../actions/pollActions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const EditChartLinkForm = (props) => {
	const {
		chartLink,
		chartLinkText,
		editPollModalOpen,
		pollData,
		setEditPollModalOpen,
	} = props;

	const dispatch = useDispatch();

	const [error, setError] = useState();
	const [disableSubmit, setDisableSubmit] = useState(false);

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const handleCancel = () => {
		setEditPollModalOpen(false);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = (data) => {
		if (data.chartLink === chartLink && data.chartLinkText === chartLinkText) {
			setEditPollModalOpen(false);
			return;
		}

		const submitData = {
			...pollData,
			chartLink: data.chartLink,
			chartLinkText: data.chartLinkText,
		};

		setDisableSubmit(true);

		axiosWithAuth()
			.put(`/api/polls/${pollData.id}`, submitData)
			.then(() => {
				dispatch(listUserPolls(userInfo._id));
				setDisableSubmit(false);
				setEditPollModalOpen(false);
			})
			.catch((err) => {
				setError(err);
				console.error(err);
			});
	};

	return (
		<>
			<Dialog
				open={editPollModalOpen}
				onClose={handleCancel}
				className='edit-poll-form-container'
			>
				<DialogTitle>Edit Chart Link</DialogTitle>
				{error && <Alert severity='error'>{error}</Alert>}
				<DialogContent>
					<form onSubmit={handleSubmit(onSubmit)} className='edit-poll-form'>
						<TextField
							label='Chart link'
							fullWidth
							margin='dense'
							size='small'
							placeholder='Chart link'
							name='chartLink'
							defaultValue={chartLink}
							{...register('chartLink')}
							error={!!errors?.chartLink}
							helperText={errors.chartLink ? errors.chartLink.message : null}
						/>
						<TextField
							label='Chart link text'
							fullWidth
							margin='dense'
							size='small'
							placeholder='Chart link text'
							name='chartLinkText'
							defaultValue={chartLinkText}
							{...register('chartLinkText')}
							error={!!errors?.chartLinkText}
							helperText={
								errors.chartLinkText ? errors.chartLinkText.message : null
							}
						/>
						<Button
							variant='contained'
							type='submit'
							sx={{ marginTop: '10px' }}
							fullWidth
							disabled={disableSubmit}
						>
							Submit
						</Button>
					</form>
					<Grid align='right' style={{ marginTop: 20 }}>
						<Button
							onClick={handleCancel}
							variant='text'
							size='small'
							margin='dense'
						>
							Cancel
						</Button>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default EditChartLinkForm;
