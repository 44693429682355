import { useEffect, useState } from 'react';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const TotalVotes = ({ location }) => {
	document.title = 'Poll Magic | Total Votes';

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState();
	const [socialText, setSocialText] = useState('');

	const pathArray = location.pathname.split('/');
	const id = pathArray[2];

	useEffect(() => {
		axios
			.get(`/api/polls/poll/${id}`)
			.then((res) => {
				if (res.data.socialProofText.includes('{%VOTES}')) {
					setSocialText(
						res.data.socialProofText.replaceAll(
							'{%VOTES}',
							res.data.totalVotes.toLocaleString()
						)
					);
				} else {
					setSocialText(res.data.socialProofText);
				}
				setLoading(false);
			})
			.catch((err) => {
				setError(err);
			});
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{loading ? (
				<CircularProgress />
			) : error ? (
				<Alert severity='error'>{error}</Alert>
			) : (
				<div
					style={{
						width: '100%',
						margin: '0 auto',
						textAlign: 'center',
						fontSize: '20px',
						fontFamily: 'sans-serif',
					}}
				>
					{socialText}
				</div>
			)}
		</div>
	);
};

export default TotalVotes;
