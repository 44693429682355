import { axiosWithAuth } from '../utils/axiosWithAuth';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import date from 'date-and-time';
import Grid from '@mui/material/Grid';
import Header from '../components/header/Navbar';
import Invoices from '../components/elements/Invoices';
import PlanOptions from '../components/elements/PlanOptions';
import Tab from '@mui/material/Tab';
import TabPanel from '../components/elements/TabPanel';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

const MyAccount = () => {
	const [customerInvoices, setCustomerInvoices] = useState([]);
	const [invoiceLoading, setInvoiceLoading] = useState(true);
	const [paymentMethod, setPaymentMethod] = useState({});
	const [plan, setPlan] = useState();
	const [planLoading, setPlanLoading] = useState(true);
	const [renewalDay, setRenewalDay] = useState();
	const [subscriptionId, setSubscriptionId] = useState();
	const [subscriptionPrice, setSubscriptionPrice] = useState();
	const [value, setValue] = useState(0);

	const paidInvoices = [];

	const setInvoices = (invoice) => {
		if (
			invoice.amount_remaining === 0 &&
			invoice.lines.data[0].description.split(' ')[0] !== 'Unused'
		) {
			paidInvoices.push(invoice);
		}
	};

	customerInvoices.forEach(setInvoices);

	const convertDate = (created) => {
		const dateInput = new Date(parseInt(`${created}000`));
		return date.format(dateInput, 'MM/DD/YYYY');
	};

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		document.title = `${process.env.REACT_APP_PAGE_TITLE} | Manage Plan`;
		setPlan(userInfo.subscription);
	}, [userInfo.subscription]);

	useEffect(() => {
		const getSubscription = (id) => {
			axiosWithAuth()
				.get(`/api/users/retrieve-subscription/${id}`)
				.then((res) => {
					setRenewalDay(convertDate(res.data.current_period_end));
					setSubscriptionPrice(res.data.plan.amount);
					getPaymentMethod();
				})
				.catch((err) => console.error(err));
		};

		const getPaymentMethod = () => {
			axiosWithAuth()
				.get(`/api/users/get-payment-source/${userInfo.stripeId}`)
				.then((res) => {
					setPaymentMethod(res.data.data[0]);
					setPlanLoading(false);
				})
				.catch((err) => console.error(err));
		};

		axiosWithAuth()
			.get(`/api/users/get-invoices/${userInfo.stripeId}`)
			.then((res) => {
				setSubscriptionId(res.data.data[0].subscription);
				setCustomerInvoices(res.data.data);
				if (customerInvoices.length) {
					setSubscriptionId(res.data.data[0].subscription);
					getSubscription(subscriptionId);
				}
			})
			.then(() => {
				setInvoiceLoading(false);
			})
			.catch((err) => console.error(err));
	}, [userInfo.stripeId, customerInvoices.length, subscriptionId]);

	const tabProps = (index) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		};
	};

	const handleChange = (e, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<Header />
			<div className='my-account-container'>
				<div className='my-account'>
					<Grid className='plans-header'>
						<Typography component='h2'>Billing</Typography>
					</Grid>

					{planLoading ? (
						<div className='plan-loading'>
							<CircularProgress />
						</div>
					) : (
						<>
							<Grid className='billing-tabs-container'>
								<Tabs
									className='tabs'
									value={value}
									onChange={handleChange}
									aria-label='account tabs'
								>
									<Tab className='tab' label='Your Plan' {...tabProps(0)} />
									<Tab className='tab' label='Invoices' {...tabProps(1)} />
									{/* <Tab className='tab' label='Usage' {...tabProps(2)} /> */}
								</Tabs>
							</Grid>

							<TabPanel className='plan-options-panel' value={value} index={0}>
								<Grid className='plan-options-container'>
									<PlanOptions
										plan={plan}
										subscriptionInterval={userInfo.subscriptionInterval}
										setPlanLoading={setPlanLoading}
									/>
								</Grid>
							</TabPanel>

							<TabPanel
								className='invoices-options-panel'
								value={value}
								index={1}
							>
								<Invoices
									paymentMethod={paymentMethod}
									customerInvoices={paidInvoices}
									renewalDay={renewalDay}
									subscriptionPrice={subscriptionPrice}
									loading={invoiceLoading}
								/>
							</TabPanel>

							<TabPanel className='usage-options-panel' value={value} index={2}>
								<Typography sx={{ color: '#fff' }}>Coming Soon</Typography>
							</TabPanel>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default MyAccount;
